import { ACTIONS } from "../../actions";

/**
 * Reducer specific to handeling all user data
 */

const initialState = {
  adminList: [],
};

export function adminListData(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case ACTIONS.GET_ALL_ADMIN_LIST:
      return {
        ...newState,
        adminList: [...action.payload],
      };

    case ACTIONS.ADD_ADMIN_LIST:
      return {
        ...newState,
        adminList: [...newState.adminList, action.payload],
      };

    default:
      return state;
  }
}
