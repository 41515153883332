import React, {useState, useEffect} from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    return(
        <> 
               {
                !isOnline &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="filled" severity="error" style={{justifyContent:"center"}}>
                       <AlertTitle> No Internet Available</AlertTitle>
                    </Alert>
                    </Stack>
               }
             {props.children}
        </>   
        )

    // if user is online, return the child component else return a custom component
    // if(isOnline){
    // return(
    //     props.children
    // )
    // } else {
    //     return(<h1>No Internet Connection. Please try again later.</h1>)
    // }
}

export default NoInternetConnection;