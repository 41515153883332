import axios from "axios";
import { dev_base_url } from "../utils/configUrl";

const uploadExcel = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/excel/uploadExcelFile`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const AddNewExcel = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/excel/addNewData`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const AppendExisting = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/excel/appendExisting`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const getExcelData = (headers) => {
  return axios
    .get(`${dev_base_url}/excel/getFileData`, headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const getApkData = (headers) => {
  return axios
    .get(`${dev_base_url}/apk/getApk`,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const uploadNewApk = (payload,headers) => {
  console.log(JSON.stringify(payload,));
  return axios
    .post(`${dev_base_url}/apk/uploadApk`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

export {
  uploadExcel,
  getExcelData,
  AddNewExcel,
  AppendExisting,
  getApkData,
  uploadNewApk,
};
