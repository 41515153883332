import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import * as Colors from "../../styled-component/colors";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { MMIButton } from "../common/Button/button";
import { Heading3Bold, Error } from "../../styled-component/Labels";

import { Textfield } from "../../styled-component/Inputs";
import { InputLabel } from "../../styled-component/Labels";
import { ACTIONS } from "../../store/actions";
import { uploadExcel, getExcelData } from "../../Services/uploadServices";
import {
  editFileData,
  addFileData,
  deleteFileData,
} from "../../Services/fileServices";
import EditPopup from "../common/editPopup";

const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 210px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "15px 25px",
    fontSize: "20px",
    fontWeight: "700",
    "@media (max-width: 768px)": {
      marginTop: "50px",
    },
  },
  tableHead: {
    background: "#f1f1f1",
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 25px",
  },
  dialog: {
    //backgroundColor: theme.palette.background.paper,
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    //color: `${theme.palette.text.primary} !important`,

    marginBottom: "100px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
  },
  dialogSmallContent: {
    padding: "0px 25px !important",
    paddingTop: "10px !important",
    paddingBottom: "5px !important",
    minHeight: "60px",
  },
  dialogActions: {
    padding: "20px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
  uploadFileBox: {
    width: "55%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid lightgray",
    padding: "0 6px 0 14px",
    marginTop: "15px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.04)",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  fileName: {
    padding: "8px 6px 10px 0",
    fontSize: "13px",
    width: "60%",
    display: "inline-block",
    position: "relative",
    //top: 4px;
  },
  gridContainer: {
    margin: "0px !important",
  },
  gridItemContainer: {
    "@media (max-width: 768px)": {
      padding: "0px !important",
    },
  },
});

function ViewFileData(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }

  let file = useSelector((state) => state.fileData);

  const getUploadedFiles = () => {
    let payloadObj = {
      admin_id: user_data.id,
    };
    getExcelData(payloadObj)
      .then((res) => {
        if (!res.data.error) {
          dispatch({
            type: ACTIONS.GET_ALL_FILE_DATA,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUploadedFiles();
    if (user_data.admin_type === 1) {
      history("/user");
    }
  }, []);

  const handleEdit = (row) => {
    setEditOpen(true);
    setSelectedItem(row);
  };

  const onClose = () => {
    setEditOpen(false);
    setSelectedItem(null);
    setAddOpen(false);
  };

  const handleAdd = () => {
    setAddOpen(true);
  };

  const handleDeleteOpen = (row) => {
    setDeleteOpen(true);
    setSelectedItem(row);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedItem(null);
  };

  const onSaveClick = (obj, type) => {
    if (obj) {
      let payloadObj = {
        admin_id: user_data.id,
        ...obj,
      };
      if (type === "edit") {
        editFileData(payloadObj)
          .then((res) => {
            if (!res.data.error) {
              getUploadedFiles();
              onClose();
              toast.success(res.data.message, { toastId: "update_error" });
            } else {
              toast.error(res.data.message, { toastId: "update_error" });
            }
          })
          .catch((err) => {
            toast.error(err.message, { toastId: "update_error" });
          });
      }
      if (type === "add") {
        addFileData(payloadObj)
          .then((res) => {
            if (!res.data.error) {
              getUploadedFiles();
              onClose();
              toast.success(res.data.message, { toastId: "update_error" });
            } else {
              toast.error(res.data.message, { toastId: "update_error" });
            }
          })
          .catch((err) => {
            toast.error(err.message, { toastId: "update_error" });
          });
      }

      //console.log(payloadObj);
    }
  };

  const onDelete = () => {
    let payloadObj = {
      admin_id: user_data.id,
      UID: selectedItem.UID,
    };
    deleteFileData(payloadObj)
      .then((res) => {
        if (!res.data.error) {
          getUploadedFiles();
          handleDeleteClose();
          toast.success(res.data.message, { toastId: "update_error" });
        } else {
          toast.error(res.data.message, { toastId: "update_error" });
        }
      })
      .catch((err) => {
        toast.error(err.message, { toastId: "update_error" });
      });
  };

  return (
    <div className="main-app-grid">
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <div className={classes.listHeader}>Uploaded Excel data</div>
              {file.fileData.length > 1 && (
                <MMIButton
                  style={{
                    width: "150px",
                    height: "48px",
                    fontSize: "16px",
                    color: "black",
                    marginRight: "2rem",
                  }}
                  color={Colors.Secondary}
                  onClick={() => handleAdd()}
                >
                  Add new row
                </MMIButton>
              )}
            </div>
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "10px", maxWidth: "98%" }}
              >
                {file.fileData.length > 1 ? (
                  <TableContainer
                    component={Paper}
                    style={{ minHeight: "500px" }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell>Hexa UID</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Name</TableCell>

                          <TableCell>Organization</TableCell>
                          <TableCell>Aux1</TableCell>
                          <TableCell>Aux2</TableCell>
                          <TableCell>Aux3</TableCell>
                          <TableCell>Notes</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {console.log(file.fileData)}
                        {file.fileData.length > 1 &&
                          file.fileData.map((row) => (
                            <TableRow
                              key={row.UID}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.hexaUID}
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    padding: "5px 0px",
                                  }}
                                >
                                  {row.type}
                                </div>
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.organization}</TableCell>
                              <TableCell>{row.aux1}</TableCell>
                              <TableCell>{row.aux2}</TableCell>
                              <TableCell>{row.aux3}</TableCell>
                              <TableCell>{row.notes}</TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                  }}
                                >
                                  <Link
                                    style={{
                                      color: "#13327c",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="Edit data">
                                      <IconButton
                                        style={{ color: "#000" }}
                                        onClick={() => handleEdit(row)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Link>{" "}
                                  <span
                                    style={{
                                      margin: "0px 8px",
                                      lineHeight: "2rem",
                                    }}
                                  >
                                    |
                                  </span>{" "}
                                  <Link
                                    style={{
                                      color: `${Colors.black}`,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip title="delete data">
                                      <IconButton
                                        style={{ color: "#000" }}
                                        onClick={() => handleDeleteOpen(row)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    Sorry! No data found{" "}
                  </div>
                )}
              </Grid>
            </Grid>
            {editOpen && (
              <EditPopup
                open={editOpen}
                flag="edit"
                onClose={onClose}
                data={selectedItem}
                onSave={onSaveClick}
              />
            )}

            {addOpen && (
              <EditPopup
                open={addOpen}
                flag="add"
                onClose={onClose}
                onSave={onSaveClick}
              />
            )}
            {deleteOpen && (
              <Dialog
                open={deleteOpen}
                maxWidth="xs"
                onClose={handleDeleteClose}
                classes={{
                  paper: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  className={classes.dialogTitle}
                >
                  Delete confirmation
                  <CloseIcon
                    onClick={handleDeleteClose}
                    style={{ cursor: "pointer" }}
                  />
                </DialogTitle>
                <DialogContent className={classes.dialogSmallContent}>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the row ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <div
                    style={{
                      fontSize: "15px",
                      color: "black",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                    onClick={handleDeleteClose}
                  >
                    No
                  </div>
                  <MMIButton
                    style={{
                      width: "70px",
                      height: "28px",
                      fontSize: "15px",
                      color: "black",
                      marginLeft: "10px",
                    }}
                    color={Colors.Secondary}
                    onClick={onDelete}
                  >
                    Yes
                  </MMIButton>
                </DialogActions>
              </Dialog>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ViewFileData;
