import axios from "axios";
import { dev_base_url } from "../utils/configUrl";

const getDistributionUrl = (headers) => {
  return axios
    .get(`${dev_base_url}/auth/getAllDistributors`,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const getDistributionUsers = (headers) => {
  return axios
    .get(`${dev_base_url}/auth/getAllDistributorUsers`,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const createUser = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/auth/create_user`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const deleteDistributor = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/auth/delete_distributor`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const changePassword = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/auth/ChangePassword`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const updateUser = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/auth/update_user`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const resendPasswordUser = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/auth/resend_password`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

export { getDistributionUrl, createUser,deleteDistributor, updateUser,getDistributionUsers,changePassword ,resendPasswordUser};
