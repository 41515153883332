import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Textfield, InputWrapper } from "../../styled-component/Inputs";

import { InputLabel, Error } from "../../styled-component/Labels";
import * as Colors from "../../styled-component/colors";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { MMIButton } from "../common/Button/button";
import { encryptToString } from "../../utils/encrypt";
import { ACTIONS } from "../../store/actions";
import visibilityIcon from "../../assests/visible-1.svg";
import unVisibilityIcon from "../../assests/unvisible-1.svg";
import { login } from "../../Services/authServices";
import Loader from "../common/Loader";
const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  gridItem: {
    width: "100%",
    marginBottom: "24px",
    position: "relative",
  },
  onDropZone: {
    height: "50px",
    width: "60%",
    display: "flex",
    border: "1px dotted black",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "30px",
    "@media (max-width: 768px)": {
      width: "95%",
    },
  },
  gridContainer: {
    margin: "0px !important",
  },
  gridItemContainer: {
    "@media (max-width: 768px)": {
      padding: "0px !important",
    },
  },
  listHeader: {
    padding: "15px 25px",
    fontSize: "20px",
    fontWeight: "700",
    "@media (max-width: 1199px)": {
      marginTop: "50px",
    },
  },
  uploadFileBox: {
    width: "55%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid lightgray",
    padding: "0 6px 0 14px",
    marginTop: "15px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.04)",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  fileName: {
    padding: "8px 6px 10px 0",
    fontSize: "13px",
    width: "60%",
    display: "inline-block",
    position: "relative",
    //top: 4px;
  },
  visibleicon: {
    width: "20px",
    height: "20px",
    objectFit: "contain",
    position: "absolute",
    right: "15px",
    top: "10px",
    cursor: "pointer",
  },
});

export default function DecryptedData(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showPage,setShowPage] = useState(false)
  const [showPassword, setShowPassword] = useState("Password");
  const [showVisibilityIcon, setShowVisibilityIcon] = useState(true);

  const [signpassword, setSignPassword] = useState("");
  const [signpasswordError, setSignpasswordError] = useState(null);
  
  const [previewModal, setPreviewModal] = useState({
    fileData: "",
    name: null,
  });

  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }

  useEffect(() => {
    dispatch({
      type: ACTIONS.UPDATE_SIDEBAR_STATE,
      payload: "decryptedFile", // can be more optimised using dispatcher
    });
    if (user_data.admin_type === 2) {
      history("/dashboard");
    }
  }, []);

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      //Do stuff in here
      handleSignIn();
    }
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    setShowLoader(true);
    if (signpassword !== "") {
      let payloadObj = {
        email: user_data.email,
        password: signpassword,
        deviceType: "web",
      };
      login(payloadObj)
        .then((res) => {
          if (!res.data.error) {
            
            setShowPage(true);
            setShowLoader(false);
          } else {
            toast.error(res.data.message, { toastId: "signin_error" });
            setShowLoader(false);
            setShowPage(false);
          }
        })
        .catch((e) => {
          console.log("e",e);
          setShowLoader(false);
          setShowPage(false);
        });
      //history("/dashboard");
    } else {
      setShowLoader(false);
      setShowPage(false);
      if (signpassword === "") {
        setSignpasswordError(true);
      }
      
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    //console.log(acceptedFiles);
    var file = acceptedFiles[0];

    var reader = new FileReader();

    var textFile = /text.*/;

    if (file.type.match(textFile)) {
      reader.onload = function (event) {
        //console.log(event.target.result);
        setPreviewModal({
          fileData: event.target.result,
          name: acceptedFiles[0].name,
        });
      };
    } else {
      toast.error("Please select a text file here", { toastId: "drop_error" });
    }
    reader.readAsText(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClose = () => {
    setPreviewModal({
      fileData: "",
      name: null,
    });
  };

  const handleDecrypt = () => {
    let encryptedString = previewModal.fileData.split("$$$$$");
    //console.log(encryptedString[5]);
    let encrytedData = [];
    for (let i = 0; i < encryptedString.length; i++) {
      let data = encryptToString(encryptedString[i]);
      encrytedData.push(data);
    }

    //console.log(encrytedData);

    let fileData = "";
    for (let item of encrytedData) {
      fileData += item + "\n";
    }

    // const fileData = JSON.stringify(text);
    const blob = new Blob([fileData], { type: "text/plain" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "decrypted_text.txt";
    link.href = url;
    link.click();
  };

  return (
    <div className="main-app-grid">
    <Loader showLoader={showLoader} />
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          
            {
              showPage === false ?
              <Grid container spacing={2} style={{justifyContent:"center"}} className={classes.gridContainer}>
              <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={6}
              
              className={classes.gridItemContainer}
            >
              <div className={classes.listHeader} style={{paddingLeft:0}}>Enter the password</div>
              
<div className={classes.gridItem}>
            <InputWrapper>
              <Textfield
                className={
                  signpasswordError === null
                    ? "textfield"
                    : signpasswordError === true
                    ? "input-error textfield"
                    : "input-success textfield"
                }
                autoComplete="off"
                placeholder="Enter your password"
                type={showPassword}
                padding={"12px 52px 12px 16px"}
                value={signpassword}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setSignPassword(e.target.value);
                    setSignpasswordError(true);
                  } else {
                    setSignPassword(e.target.value);
                    setSignpasswordError(false);
                  }
                }}
                onKeyPress={enterPressed}
              />

              {signpasswordError && (
                <Error
                  className="inputerror"
                  text="Enter your password"
                  color={Colors.error}
                  margin={"4px 0 8px 0"}
                />
              )}

              {!showVisibilityIcon ? (
                <img
                  src={visibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setShowVisibilityIcon(!showVisibilityIcon);
                    setShowPassword(
                      showPassword === "Password" ? "text" : "Password"
                    );
                  }}
                  alt="name"
                />
              ) : (
                <img
                  src={unVisibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setShowVisibilityIcon(!showVisibilityIcon);
                    setShowPassword(
                      showPassword === "Password" ? "text" : "Password"
                    );
                  }}
                  alt="name"
                />
              )}
            </InputWrapper>
          </div>
          <MMIButton
            style={{
              width: "170px",
              height: "48px",
              fontSize: "15px",
              margin: "12px 0px 32px",
            }}
            color={Colors.Secondary}
            onClick={handleSignIn}
          >
            Continue
          </MMIButton>
              </Grid> 
              </Grid>
              :
              <Grid container spacing={2} className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={6}
              className={classes.gridItemContainer}
            >
              <div className={classes.listHeader}>Drop the Text file</div>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "50px",
                  flexDirection: "row",
                }}
              >
                <div className={classes.onDropZone}>
                  <div {...getRootProps()}>
                    <input type="file" {...getInputProps()} />
                    {isDragActive ? (
                      <div>
                        <div>{props.children}</div>
                        <p>Drop image here.</p>
                      </div>
                    ) : (
                      <div>
                        <div>{props.children}</div>
                        <span style={{ display: "flex" }}>
                          <TextSnippetIcon />
                          <p>
                            Drop text file here, or click to select an file.
                          </p>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {previewModal.name && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "20px",
                  }}
                >
                  <div className={classes.uploadFileBox}>
                    <span className={classes.fileName}>
                      {previewModal.name}
                    </span>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
              <div style={{ display: "flex", marginTop: "20px" }}>
                {previewModal.name && (
                  <div
                    style={{
                      paddingLeft: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <MMIButton
                      style={{
                        width: "150px",
                        height: "48px",
                        fontSize: "15px",
                        //margin: "12px 0px 32px",
                      }}
                      color={Colors.Secondary}
                      onClick={handleDecrypt}
                    >
                      Decrypt
                    </MMIButton>
                  </div>
                )}
              </div>
            </Grid>
            </Grid>
          }
        </div>
      </div>
    </div>
  );
}
