import axios from "axios";
import { dev_base_url } from "../utils/configUrl";

const login = (payload) => {
  return axios
    .post(`${dev_base_url}/auth/login`, payload)
    .then((response) => response)
    .catch((e) => e.response);
};

const forgotPassword = (payload) => {
  return axios
    .post(`${dev_base_url}/auth/forgot_password`, payload)
    .then((response) => response)
    .catch((e) => e.response);
};

export { login, forgotPassword };
