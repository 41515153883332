import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import * as Colors from "../../styled-component/colors";
import { MMIButton } from "./Button/button";
import {
  getExcelData,
  uploadExcel,
  AddNewExcel,
  AppendExisting,
} from "../../Services/uploadServices";
import WarningPopup from "../common/warningPopup";
import ErrorPopup from "./ErrorPopup";
import Loader from "./Loader";

const useStyles = makeStyles({
  dialog: {
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
    minHeight: "500px",
    overflowX: "hidden",
  },
  dialogActions: {
    //padding: "10px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
  gridContainer: {
    margin: "0px !important",
  },
  gridItemContainer: {
    "@media (max-width: 768px)": {
      padding: "0px !important",
    },
  },
  uploadFileBox: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid lightgray",
    padding: "0 6px 0 14px",
    marginTop: "15px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.04)",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  onDropZone: {
    height: "50px",
    width: "70%",
    display: "flex",
    border: "1px dotted black",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "30px",
    "@media (max-width: 768px)": {
      width: "95%",
    },
  },
  dropZoneContainer: {
    display: "flex",
    paddingLeft: "50px",
    flexDirection: "row",
    "@media (max-width: 768px)": {
      paddingLeft: "0px",
    },
  },
  fileName: {
    padding: "8px 6px 10px 0",
    fontSize: "13px",
    width: "60%",
    display: "inline-block",
    position: "relative",
    //top: 4px;
  },
  uploadButtons: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 768px)": {
      display: "block",
    },
  },
  listHeader: {
    "@media (max-width: 768px)": {
      marginTop: "10px",
    },
  },
  uploadFileBoxContainer: {
    paddingLeft: "50px",
    marginTop: "20px",
    "@media (max-width: 768px)": {
      paddingLeft: "0px",
    },
  },
});

export default function UploadFilePopup(props) {
  const classes = useStyles();

  const { open, userData } = props.data;

  const [previewModal, setPreviewModal] = useState({
    fileData: "",
    name: null,
  });

  const [file_data, setFileData] = useState({
    fileName: null,
    fileData: [],
  });
  const [conflictPopup, setConflictPopup] = useState({
    open: false,
    data: [],
  });

  const [errorPopup, setErrorPopup] = useState({
    open: false,
    data: [],
  });
  const [showLoader, setShowLoader] = useState(false);


  const getUploadedFiles = () => {
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
    };
   
    getExcelData(config).then((res) => {
      if (!res.data.error) {
        //console.log(res.data.data);
        setFileData({
          fileName: res.data.data.fileName,
          fileData: res.data.data.fileData,
        });
      }
    });
  };

  useEffect(() => {
    getUploadedFiles();
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    setPreviewModal({
      fileData: acceptedFiles[0],
      name: acceptedFiles[0].name,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onUpload = () => {
    setShowLoader(true);
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
  };
    let formData = new FormData();
    formData.append("uploadFile", previewModal.fileData);
    formData.append("groupId", userData._id);
    uploadExcel(formData,config)
      .then((res) => {
        if (!res.data.error) {
          setPreviewModal({
            fileData: "",
            name: null,
          });
          getUploadedFiles();
          toast.success("upload sucessfully", { toastId: "upload_sucess" });
          setShowLoader(false);
        } else {
          if(res.data.errorData && res.data.errorData.length>0){
            setErrorPopup({
              open:true,
              data:res.data.errorData
            })
          }
          toast.error(res.data.message, { toastId: "upload_error" });
          setShowLoader(false);
        }
      })
      .catch((e) => {
        setShowLoader(false);
        console.log(e);
      });
  };

  const onAddNew = () => {
    setShowLoader(true);
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
  };
    let formData = new FormData();
    formData.append("uploadFile", previewModal.fileData);
    formData.append("groupId", userData._id);
    AddNewExcel(formData,config)
      .then((res) => {
        if (!res.data.error) {
          setPreviewModal({
            fileData: "",
            name: null,
          });
          getUploadedFiles();
          setShowLoader(false);
          toast.success("updated sucessfully", { toastId: "update_sucess" });
        } else {
          if(res.data.errorData && res.data.errorData.length>0){
            setErrorPopup({
              open:true,
              data:res.data.errorData
            })
          }
          setShowLoader(false);
          toast.error(res.data.message, { toastId: "update_error" });
        }
      })
      .catch((e) => {
        console.log(e);
        setShowLoader(false);
      });
  };

  const handleClose = () => {
    setPreviewModal({
      fileData: "",
      name: null,
    });
  };

  const handleConflictClose = () => {
    setConflictPopup({
      open: false,
      data: [],
    });
  };

  const handleErrorPopupClose = () => {
    setErrorPopup({
      open: false,
      data: [],
    });
  };

  const onAppend = () => {
    setShowLoader(true);
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
  };
    let formData = new FormData();
    formData.append("uploadFile", previewModal.fileData);
    formData.append("groupId", userData._id);
    // formData.append("uploaded_data", JSON.stringify(file_data.fileData));

    AppendExisting(formData,config)
      .then((res) => {
        if (!res.data.error) {
          setPreviewModal({
            fileData: "",
            name: null,
          });
          getUploadedFiles();
          setShowLoader(false);
          if (res.data.conflictData.length > 0) {
            setConflictPopup({
              open: true,
              data: [...res.data.conflictData],
            });
          } else {
            setShowLoader(false);
            toast.success(res.data.message, { toastId: "update_sucess" });
          }
        } else {
          if(res.data.errorData && res.data.errorData.length>0){
            setErrorPopup({
              open:true,
              data:res.data.errorData
            })
          }
          setShowLoader(false);
          toast.error(res.data.message, { toastId: "update_error" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <Loader showLoader={showLoader} />
      <DialogTitle className={classes.dialogTitle}>
        Upload / Replace file
        {/*{strings.EditStaff*/}
        <CloseIcon onClick={props.onClose} style={{ cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            textAlign: "center",
            padding: "10px 0px",
            textWeight: "700",
            color: "black",
          }}
        >
          Upload excel file for {userData.firstname} {userData.lastname}
        </DialogContentText>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            className={classes.gridItemContainer}
          >
            <div className={classes.listHeader}>Upload the Excel file</div>

            <div className={classes.dropZoneContainer}>
              <div className={classes.onDropZone}>
                <div {...getRootProps()}>
                  <input type="file" {...getInputProps()} />
                  {isDragActive ? (
                    <div>
                      <div>{props.children}</div>
                      <p>Drop image here.</p>
                    </div>
                  ) : (
                    <div>
                      <div>{props.children}</div>
                      <p>Drop file here, or click to select an file.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {previewModal.name && (
              <div className={classes.uploadFileBoxContainer}>
                <div className={classes.uploadFileBox}>
                  <span className={classes.fileName}>{previewModal.name}</span>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            )}
            <div className={classes.uploadButtons}>
              {previewModal.name && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "20px",
                  }}
                >
                  <MMIButton
                    style={{
                      width: "150px",
                      height: "48px",
                      fontSize: "15px",
                      //margin: "12px 0px 32px",
                    }}
                    color={Colors.Secondary}
                    onClick={file_data.fileName ? onAddNew : onUpload}
                  >
                    {file_data.fileName ? "Add as new" : "Upload"}
                  </MMIButton>
                </div>
              )}
              {previewModal.name && file_data.fileName && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "20px",
                  }}
                >
                  <MMIButton
                    style={{
                      width: "150px",
                      height: "48px",
                      fontSize: "15px",
                      //margin: "12px 0px 32px",
                    }}
                    color={Colors.Secondary}
                    onClick={onAppend}
                  >
                    Append Existing
                  </MMIButton>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            xl={6}
            className={classes.gridItemContainer}
          >
            <div className={classes.listHeader}>Uploaded file</div>
            {file_data.fileName && (
              <div
                style={{
                  paddingLeft: "50px",
                  marginTop: "30px",
                }}
              >
                <div className={classes.uploadFileBox}>
                  <span className={classes.fileName}>{file_data.fileName}</span>
                  {/* <DeleteIcon
                    //onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  /> */}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <MMIButton onClick={props.onClose}> Cancel</MMIButton>
      </DialogActions>
      {conflictPopup && (
        <WarningPopup
          popupOpen={conflictPopup.open}
          handleClose={handleConflictClose}
          conflict_data={conflictPopup.data}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          popupOpen={errorPopup.open}
          handleClose={handleErrorPopupClose}
          errorData={errorPopup.data}
        />
      )}
    </Dialog>
  );
}
