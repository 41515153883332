import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useLocation } from "react-router";
import { createBrowserHistory } from "history";
import ScrollToTop from "./Helper/ScrollToTop/scrollToTop";
import AppRoutes from "./routes/index";
import NoInternetConnection from "./component/common/NoInternetConnection";

function App() {
  const history = createBrowserHistory();
  //const location = useLocation();

  console.log(history);

  return (
    <Router history={history}>
    <NoInternetConnection>
      <ScrollToTop />
      <AppRoutes />
      </NoInternetConnection>
    </Router>
  );
}

export default App;
