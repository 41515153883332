import { combineReducers } from "redux";
import { userData } from "./userReducer/userDataReducers";
import { fileData } from "./fileReducer/fileDataReducer";
import { adminListData } from "./adminDataReducer/adminDataReducer";
import { apkData } from "./apkReducers/apkReducer";
import { sideBarReducer } from "./sidebarReducer/sidebarReducer";

const RootReducer = combineReducers({
  userData: userData,
  fileData: fileData,
  adminListData: adminListData,
  apkData: apkData,
  sidebarData: sideBarReducer,
});

export default RootReducer;
