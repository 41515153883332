import React from "react";
import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { getExcelData } from "../../Services/uploadServices";
import { decimalToHex } from "../../utils/Constant";

const ExporExcel = ({ group_id, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const getUploadedFiles = async() => {
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id }
  };
    getExcelData(config).then((res) => {
      if (!res.data.error) {
        const newArr = res.data.data.fileData.map(({_id,UID,userId, ...rest}) => {
          return rest;
        });
        // if(newArr.length <= 200){
            var ws = XLSX.utils.json_to_sheet(newArr,{ origin: 'A3', skipHeader: true });
            XLSX.utils.sheet_add_aoa(ws, [["PROPRIETARY  Microwave Monolithics Incorporated"]], { origin: 'A1' });
            XLSX.utils.sheet_add_aoa(ws, [["UID", "Type","Name","Organization","Aux1","Aux2","Aux3","Notes"]], { origin: "A3" });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
          // }
        }
    });
  };

  const exportToExcel = async () => {
   getUploadedFiles();
  };

  return (
    <Button style={{
        display: "flex",
        alignItems: "center",
        width: "150px",
        height: "48px",
        fontSize: "16px",
        justifyContent:"center",
        margin:"0 10px"
      }} variant="contained" onClick={(e) => exportToExcel(fileName)}>
        Export Excel
    </Button>
  );
};

export default ExporExcel;
