import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import visibilityIcon from "../../assests/visible-1.svg";
import unVisibilityIcon from "../../assests/unvisible-1.svg";
import * as Colors from "../../styled-component/colors";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { MMIButton } from "../common/Button/button";
import { Heading3Bold, Error } from "../../styled-component/Labels";

import { Textfield,InputWrapper } from "../../styled-component/Inputs";
import { InputLabel } from "../../styled-component/Labels";
import { ACTIONS } from "../../store/actions";
import { toast } from "react-toastify";
import UploadFilePopup from "../common/uploadFilePopup";
import ViewFilePopup from "../common/viewFilePopup";
import { changePassword, createUser, getDistributionUrl } from "../../Services/userServices";

function ChangePassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setconfPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [oldpasswordError, setOldPasswordError] = useState(false);
  const [confpasswordError, setConfPasswordError] = useState(false);
  const [oldPasswordType, setOldPasswordType] = useState("Password");
  const [newPasswordType, setNewPasswordType] = useState("Password");
  const [confNewPasswordType, setConfNewPasswordType] = useState("Password");
  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }

  const handleChangePassword = (e) =>{
    e.preventDefault();
    if (
      password !== "" &&
      confPassword !== "" &&
      oldPassword !== "" &&
      password == confPassword
    ) {
      let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
      let payloadObj = {
        oldPassword: oldPassword,
        password: password,
        confirmPassword: confPassword
       
      };
      changePassword(payloadObj,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            localStorage.setItem(
              "UserData",
              JSON.stringify({
                email: userData.email,
                id: userData.id,
                token: userData.token,
                role: userData.role,
                firstname:userData.firstname,
                lastname:userData.lastname,
                isFirstTime:res.data.data.isFirstTime,
                groupData:userData.groupData
              })
            );
            if (userData.role === 1) {
              history("/distribution_list");
            }else if (userData.role === 2) {
              history("/groups");
            }else if (userData.role === 3) {
              history("/groups-user");
            } else {
              history("/");
            }
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
    } else {
      if (oldPassword === "") {
        setOldPasswordError(true);
      }
      if (password === "") {
        setPasswordError(true);
      }
      if (confPassword !== password) {
        setConfPasswordError(true);
      }
     }
  }




  return (
    <div className="main-app-grid">
      {/* <Sidebar /> */}
      <Header />
      <div className={classes.mainMidContainer} style={{paddingLeft:"24px"}}>
        <div className={classes.homepageHolder}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              textAlign={"center"}
              justifyContent={"space-between"}
              margin={"0px 0px 24px 0px"}
            ></Grid>
            <Grid item xs={12}>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  textAlign: "center",
                  padding: "10px 0px",
                  textWeight: "700",
                  color: "black",
                }}
              >
              Change Password
                
              </DialogContentText>
              
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Old Password"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <InputWrapper>
                <Textfield
                  className="textfield"
                  placeholder={""}
                  value={oldPassword}
                  type={oldPasswordType}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setOldPasswordError(false);
                  }}
                />
                {oldpasswordError && (
                  <Error
                    className="inputerror"
                    text={"Old Password is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
                {oldPasswordType === "Password" ? (
                <img
                  src={visibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setOldPasswordType("text");
                  }}
                  alt="name"
                />
              ) : (
                <img
                  src={unVisibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setOldPasswordType("Password");
                  }}
                  alt="name"
                />
              )}
                </InputWrapper>
              </div>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="New Password"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <InputWrapper>
                <Textfield
                  className="textfield"
                  placeholder={""}
                  value={password}
                  type={newPasswordType}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                />
                {passwordError && (
                  <Error
                    className="inputerror"
                    text={"New Password is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
                {newPasswordType === "Password" ? (
                <img
                  src={visibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setNewPasswordType("text");
                  }}
                  alt="name"
                />
              ) : (
                <img
                  src={unVisibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setNewPasswordType("Password");
                  }}
                  alt="name"
                />
              )}
                </InputWrapper>
              </div>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Confirm New Password"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <InputWrapper>
                <Textfield
                  className="textfield"
                  placeholder={""}
                  type={confNewPasswordType}
                  value={confPassword}
                  onChange={(e) => {
                    setconfPassword(e.target.value);
                    setConfPasswordError(false);
                  }}
                />
                {confpasswordError && (
                  <Error
                    className="inputerror"
                    text={"Password are not match"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
                {confNewPasswordType === "Password" ? (
                <img
                  src={visibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setConfNewPasswordType("text");
                  }}
                  alt="name"
                />
              ) : (
                <img
                  src={unVisibilityIcon}
                  className={classes.visibleicon}
                  onClick={() => {
                    setConfNewPasswordType("Password");
                  }}
                  alt="name"
                />
              )}
                </InputWrapper>
              </div>
              <div className={classes.dialogGridItem} style={{padding:"20px 0 0 0"}}>
                <MMIButton onClick={handleChangePassword}> Change Password</MMIButton>
              </div>
            </DialogContent>
            
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;


const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },
  gridContainer: {
    padding: "0px 25px",
    "@media (max-width: 991px)": {
      padding: "0px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "700",
  },
  tableHead: {
    background: "#f1f1f1",
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 25px",
  },
  dialog: {
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
  },
  dialogActions: {
    //padding: "20px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
  visibleicon: {
    width: "20px",
    height: "20px",
    objectFit: "contain",
    position: "absolute",
    right: "15px",
    top: "10px",
    cursor: "pointer",
  },
});

let roles = [
  {
    value: "2",
    label: "Admin",
  },
];
