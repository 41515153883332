import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ScaleLoader from "react-spinners/ScaleLoader";

import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from '@mui/icons-material/Delete';

import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import {
  deleteEncyptedData,
  getAllEncryptedData,
  getEncryptedFile,
} from "../../Services/fileServices";
import Loader from "../common/Loader";
import * as Colors from "../../styled-component/colors";
const override = {
  display: "flex",
  alignItems: "center",
  marginRight: "15px",
  marginTop: "15px",
};

const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 210px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  gridContainer: {
    margin: "0px !important",
  },
  gridItemContainer: {
    "@media (max-width: 768px)": {
      padding: "0px !important",
    },
  },
  listHeader: {
    padding: "15px 25px",
    fontSize: "20px",
    fontWeight: "700",
    "@media (max-width: 768px)": {
      marginTop: "50px",
    },
  },
  tableHead: {
    background: "#f1f1f1",
  },
});

export default function EncryptedFiles() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  const [encryptedData, setEncryptedData] = useState(null);
  const [loader, setLoader] = useState({
    id: null,
    load: false,
  });
  const [showLoader, setShowLoader] = useState(true);
  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }

  const removeFile = (data) => {
    setShowLoader(true);
    let userData = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { 
        Authorization: `Bearer ${userData.token}`, 
       }}
    let payloadObj = {
      admin_id: params.id ? params.id : userData.id,
      file_id:data._id
    };
    deleteEncyptedData(payloadObj,config)
      .then((res) => {
        if (!res.data.error) {
          toast.success(res.data.message, { toastId: "adminCreate_error" });
          setShowLoader(false);
          getAllList()
        }else{
          setShowLoader(false);
          toast.error(res.data.message, { toastId: "signin_error" });
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  const getAllList = () => {
    setShowLoader(true);
    let payloadObj = {
      admin_id: params.id ? params.id : user_data.id,
    };
    getAllEncryptedData(payloadObj)
      .then((res) => {
        if (!res.data.error) {
          setEncryptedData(res.data.data);
          setShowLoader(false);
        }else{
          setShowLoader(false);
          toast.error(res.data.message, { toastId: "signin_error" });
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getAllList();
  }, []);

  const handleDownload = (row) => {
    console.log(row);
    let payloadObj = {
      file_id: row._id,
    };
    setLoader({
      id: row._id,
      loaded: true,
    });
    getEncryptedFile(payloadObj)
      .then((res) => {
        if (!res.data.error) {
          let url = res.data.data.file_url;
          const link = document.createElement("a");
          link.download = res.data.data.name;
          link.href = url;
          link.click();
          setLoader({
            id: null,
            load: false,
          });
        } else {
          setLoader({
            id: null,
            load: false,
          });
          toast.error("Not able download file!", { toastId: "download_error" });
        }
      })
      .catch((err) => {
        setLoader({
          id: null,
          load: false,
        });
        toast.error(err.message, { toastId: "download_error" });
      });
  };

  return (
    <div className="main-app-grid">
    <Loader showLoader={showLoader} />
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            <div className={classes.listHeader}>Encrypted Files</div>
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} style={{ paddingLeft: "10px", maxWidth: "98%" }}>
              {encryptedData && encryptedData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  style={{ minHeight: "500px" }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>SL No</TableCell>
                        <TableCell>File Name</TableCell>
                        <TableCell>Created Date & Time</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {encryptedData &&
                        encryptedData.length > 0 &&
                        encryptedData.map((row, index) => (
                          <TableRow
                            key={row.index + 1}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>

                            <TableCell>{row.fileName}</TableCell>
                            <TableCell>
                              {row.createdAt
                                ? moment(row.createdAt).format("DD/MM/YYYY") +
                                  " , " +
                                  moment(row.createdAt).format("LT")
                                : null}
                            </TableCell>

                            <TableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "5px",
                                }}
                              >
                                <Link
                                  style={{
                                    color: "#13327c",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleDownload(row)}
                                >
                                  {loader.id === row._id && loader.loaded ? (
                                    <ScaleLoader
                                      color={"#000"}
                                      loading={loader}
                                      cssOverride={override}
                                      size={50}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  ) : (
                                    <Tooltip title="download">
                                      <IconButton
                                        style={{ color: "#000" }}
                                        //onClick={() => handleEdit(row)}
                                      >
                                        <FileDownloadIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  Download File
                                </Link>
                                <span style={{ margin: "10px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => { if (window.confirm('Are you sure you want to delete logs file?')) removeFile(row) } } 
                               
                              >
                               <DeleteIcon/>
                              
                              </Link>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  Sorry! No data found{" "}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
