import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

import { MMIButton } from "./Button/button";
import { Heading3Bold, Error, InputLabel } from "../../styled-component/Labels";

import { Textfield } from "../../styled-component/Inputs";
import * as Colors from "../../styled-component/colors";
import { decimalToHex, hexToDecimal } from "../../utils/Constant";

const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
  },
  gridContainer: {
    padding: "0px 25px",
    "@media (max-width: 991px)": {
      padding: "0px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "700",
  },
  tableHead: {
    background: "#f1f1f1",
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 25px",
  },
  dialog: {
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
    display: "flex",
    flexWrap: "wrap",
  },
  dialogActions: {
    padding: "15px !important",
    display: "flex",
  },
  dialogGridItem: {
    width: "45%",
    margin: "auto",
    // "@media (max-width: 991px)": {
    //   width: "100%",
    // },
  },
  dialogTextField: {
    width: "100%",
  },
});

export default function EditPopup(props) {
  const classes = useStyles();
  const { open, onClose, flag, data, onSave } = props;

  const [errorMessage, setErrorMessage] = useState({
    open: false,
    message: "",
  });

  const [rowData, setRowData] = useState({
    UID: data ? data.UID : "",
    hexaUID: data ? data.hexaUID : "",
    type: data ? data.type : "",
    name: data ? data.name : "",
    organization: data ? data.organization : "",
    aux1: data ? data.aux1 : "",
    aux2: data ? data.aux2 : "",
    aux3: data ? data.aux3 : "",
    notes: data ? data.notes : "",
  });

  const handleClick = () => {
    if (flag === "edit") {
      onSave(rowData, flag);
    } else {
      if (rowData.hexaUID === "") {
        setErrorMessage({
          open: true,
          message: "Please Enter unique UID",
        });
        return;
      }
      if (
        rowData.type === "" ||
        rowData.name === "" ||
        rowData.organization === "" ||
        rowData.aux1 === "" ||
        rowData.aux2 === "" ||
        rowData.aux3 === "" ||
        rowData.notes === ""
      ) {
        setErrorMessage({
          open: true,
          message: "Please fill all details",
        });
        return;
      }
      onSave(rowData, flag);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        {flag === "edit" ? "Edit Table data" : "Add New Data"}

        <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="UID"
            color={Colors.nightGray}
            padding={"0 0 8px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter UID"}
            value={rowData.hexaUID}
            disabled={flag === "edit"}
            onChange={(e) => {
              setRowData({
                ...rowData,
                hexaUID: e.target.value
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Type"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter Type"}
            value={rowData.type}
            onChange={(e) => {
              setRowData({
                ...rowData,
                type: e.target.value,
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Name"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter Name"}
            value={rowData.name}
            onChange={(e) => {
              setRowData({
                ...rowData,
                name: e.target.value,
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Organization"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter organization"}
            value={rowData.organization}
            onChange={(e) => {
              setRowData({
                ...rowData,
                organization: e.target.value,
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Aux 1"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter aux1"}
            value={rowData.aux1}
            onChange={(e) => {
              setRowData({
                ...rowData,
                aux1: e.target.value,
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Aux 2"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter aux2"}
            value={rowData.aux2}
            onChange={(e) => {
              setRowData({
                ...rowData,
                aux2: e.target.value,
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Aux 3"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter aux3"}
            value={rowData.aux3}
            onChange={(e) => {
              setRowData({
                ...rowData,
                aux3: e.target.value,
              });
            }}
          />
        </div>
        <div className={classes.dialogGridItem}>
          <InputLabel
            text="Notes"
            color={Colors.nightGray}
            padding={"0 0 5px 0"}
            className={"inputlabel"}
            style={{ marginTop: "15px" }}
          />
          <Textfield
            className="textfield"
            placeholder={"Enter Notes"}
            value={rowData.notes}
            onChange={(e) => {
              setRowData({
                ...rowData,
                notes: e.target.value,
              });
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {errorMessage.open ? (
          <span
            style={{
              color: "red",
              fontSize: "14px",
              flex: "1",
              paddingLeft: "10px",

              display: "flex",
              alignItems: "center",
            }}
            id="nw-EventError"
          >
            <ErrorIcon style={{ marginRight: "5px" }} />
            {errorMessage.message}
          </span>
        ) : (
          ""
        )}
        <span>
          <MMIButton onClick={onClose}> Cancel</MMIButton>
          <MMIButton onClick={handleClick} style={{ marginLeft: "0.5rem" }}>
            {" "}
            {flag === "edit" ? "Update data" : "Add data"}
          </MMIButton>
        </span>
      </DialogActions>
    </Dialog>
  );
}
