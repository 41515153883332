import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader({showLoader}) {
    console.log("showLoadershowLoader",showLoader)
  return (
    <>
        {showLoader &&
            <Box sx={{ display: 'flex',position:"absolute",zIndex:"100000",justifyContent:"center",width:"100%",height:"100%",alignItems:"center",background:"#f1f1f1b5" }}>
                <CircularProgress />
            </Box>
         }
    </>
  );
}