import { ACTIONS } from "../../actions";

/**
 * Reducer specific to handeling all user data
 */

const initialState = {
  fileName: null,
  fileData: [],
};

export function fileData(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case ACTIONS.GET_ALL_FILE_DATA:
      return {
        ...newState,
        fileName: action.payload.fileName,
        fileData: [...action.payload.fileData],
      };

    default:
      return state;
  }
}
