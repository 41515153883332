import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import * as Colors from "../../../styled-component/colors";
import { MMIButton } from "../Button/button";

import headerLogo from "../../../assests/ic_launcher-playstore.png";

const useStyles = makeStyles({
  LoginHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "80px",
    backgroundColor: "white",
    flexDirection: "row",
    paddingLeft: "120px",
    paddingRight: "120px",
    width: "100%",
    boxSizing: "border-box",
    "@media (max-width: 991px)": {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  loginLogo: {
    height: "54px",
  },
  buttonsHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

function LoginHeader() {
  const classes = useStyles();

  return (
    <div className={classes.LoginHeader}>
      <>
        <img
          height={54}
          src={headerLogo}
          alt="icon"
          className={classes.loginLogo}
        />
        <div className={classes.buttonsHolder}>
          <div>Signin</div>
        </div>
      </>
    </div>
  );
}

export default LoginHeader;
