import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import * as Colors from "../../styled-component/colors";
import { MMIButton } from "./Button/button";

const useStyles = makeStyles({
  dialog: {
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginBottom: "100px !important",
    marginTop: "20px !important",
    width: "448px",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
  },
  dialogActions: {
    padding: "20px !important",
  },
});

export default function WarningPopup(props) {
  const classes = useStyles();

  const { popupOpen, handleClose, conflict_data } = props;
  return (
    <Dialog
      open={popupOpen}
      maxWidth="xs"
      onClose={handleClose}
      classes={{
        paper: classes.dialog,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        Data conflict warning
        <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          The following UID(s) in the new excel file habe overwritten the
          entires with the same UID(s) in the database:
          {conflict_data.length > 0 && (
            <div>
              {conflict_data.map((item,index) => {
                return <span>{item.hexaUID}{index===conflict_data.length-1 ? "" : ", "} </span>;
              })}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <MMIButton
          style={{
            width: "70px",
            height: "28px",
            fontSize: "15px",
            color: "black",
            marginLeft: "10px",
          }}
          color={Colors.Secondary}
          onClick={handleClose}
        >
          Ok
        </MMIButton>
        {/*{strings.SaveChanges}*/}
      </DialogActions>
    </Dialog>
  );
}
