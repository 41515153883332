import axios from "axios";
import { dev_base_url } from "../utils/configUrl";

const editFileData = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/excel/updateExcelData`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const addFileData = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/excel/addExcelData`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const deleteFileData = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/excel/deleteRow`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const deleteEncyptedData = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/upload/deleteFile`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const getAllEncryptedData = (payload) => {
  return axios
    .post(`${dev_base_url}/upload/getAllEncryptedFile`, payload)
    .then((response) => response)
    .catch((e) => e.response);
};

const getEncryptedFile = (payload) => {
  return axios
    .post(`${dev_base_url}/upload/getEncryptedFile`, payload)
    .then((response) => response)
    .catch((e) => e.response);
};

export {
  editFileData,
  addFileData,
  deleteFileData,
  getAllEncryptedData,
  getEncryptedFile,
  deleteEncyptedData
};
