import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS } from "../../../store/actions";
import { Link } from "react-router-dom";

import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PreviewIcon from "@mui/icons-material/Preview";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import "./sidebar.css";

function Sidebar(props) {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  let user_data = JSON.parse(localStorage.getItem("UserData"));
  let sidebar_state = useSelector((state) => state.sidebarData.sideBar);



  const handleClick = () => {
    setOpen(!open);
  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className={isActive ? "sidebar toggleSidebar" : "sidebar"}>
      <div onClick={toggleClass} className={"sideArrow"}>
        <MenuIcon className={isActive ? "menuIcon close" : "menuIcon"} />
        <CloseIcon className={isActive ? "closeIcon" : "closeIcon close"} />
      </div>
      <div className="sidebar-header"></div>

      <div className="sidebar-manage-strings-section">
        {user_data.role === 2 && (
          <>
            <div
              className={
                sidebar_state === "distributor_list"
                  ? `sidebar-button-holder selected`
                  : `sidebar-button-holder`
              }
            >
              <HomeIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/groups");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "distributor_list",
                  });
                }}
              >
                Home
              </div>
            </div>

            {/* <div
              className={
                sidebar_state === "viewFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <PreviewIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/viewFile");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "viewFile",
                  });
                }}
              >
                View file data
              </div>
            </div> */}
            <div
              className={
                sidebar_state === "decryptedFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <FolderSpecialIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/decryptedFile");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "decryptedFile",
                  });
                }}
              >
                Decrypted File
              </div>
            </div>
            {/* <div
              className={
                sidebar_state === "encryptedFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <FileCopyIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/encryptedfiles");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "encryptedFile",
                  });
                }}
              >
                Encrypted files
              </div>
            </div> */}
          </>
        )}

        {user_data.role === 3 && (
          <>
            <div
              className={
                sidebar_state === "distributor_list"
                  ? `sidebar-button-holder selected`
                  : `sidebar-button-holder`
              }
            >
              <HomeIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/groups-user");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "distributor_list",
                  });
                }}
              >
                Home
              </div>
            </div>

            {/* <div
              className={
                sidebar_state === "viewFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <PreviewIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/viewFile");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "viewFile",
                  });
                }}
              >
                View file data
              </div>
            </div> */}
            <div
              className={
                sidebar_state === "decryptedFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <FolderSpecialIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/decryptedFile");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "decryptedFile",
                  });
                }}
              >
                Decrypted File
              </div>
            </div>
            <div
              className={
                sidebar_state === "encryptedFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <FileCopyIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/encryptedfiles");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "encryptedFile",
                  });
                }}
              >
                Encrypted files
              </div>
            </div>
          </>
        )}

        {user_data.role === 1 && (
          <>
            <div
              className={
                sidebar_state === "distributor_list"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
              //onClick={handleClick}
            >
              {/* <img src={gigsWhiteIcon} alt="name" /> */}
              <GroupIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/distribution_list");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "distributor_list",
                  });
                }}
              >
                Distributors
              </div>
            </div>
            <div
              className={
                sidebar_state === "apkUpload"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              {/* <img src={homeWhiteIcon} alt="name" /> */}
              <PreviewIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/apkUpload");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "apkUpload",
                  });
                }}
              >
                Upload Apk
              </div>
            </div>

            <div
              className={
                sidebar_state === "decryptedFile"
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
            >
              <FolderSpecialIcon />
              <div
                style={{ padding: "0px 13px", cursor: "pointer" }}
                onClick={() => {
                  history("/decryptedFile");
                  dispatch({
                    type: ACTIONS.UPDATE_SIDEBAR_STATE,
                    payload: "decryptedFile",
                  });
                }}
              >
                Decrypted File
              </div>
            </div>
          </>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Link to="/gig">Active</Link>
          <Link to="/gig-completed-list">Completed</Link>
        </Collapse>
      </div>
    </div>
  );
}

export default Sidebar;
