import * as React from "react";
import { Navigate,useLocation } from "react-router-dom";

const rolesRoute = {
  1:"/distribution_list",
  2:"/groups",
  3:"/groups-user",
  4:"/"
}

const LoggedOutRoute = ({ children }) => {
  let location = useLocation();
  let userData = JSON.parse(localStorage.getItem("UserData"));
  // alert("userDatauserDatauserData")
  if (userData != null) {
    // Redirect them to the /dashboard page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if(userData.isFirstTime===true){
      return <Navigate to="/change_password" state={{ from: location }} />;
    }
    return <Navigate to={`${rolesRoute[userData['role']]}`} state={{ from: location }} />;
  }
  return children;
};

export default LoggedOutRoute;
