import { ACTIONS } from "../../actions";

/**
 * Reducer specific to handeling all user data
 */

const initialState = {
  sideBar: "dashboard",
};

export function sideBarReducer(state = initialState, action) {
  let newState = { ...state };

  switch (action.type) {
    case ACTIONS.UPDATE_SIDEBAR_STATE:
      console.log(action.payload);
      return {
        ...newState,
        sideBar: action.payload,
      };

    default:
      return state;
  }
}
