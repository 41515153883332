import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from '@mui/icons-material/Edit';
import * as Colors from "../../styled-component/colors";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { MMIButton } from "../common/Button/button";
import { Heading3Bold, Error } from "../../styled-component/Labels";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Textfield } from "../../styled-component/Inputs";
import { InputLabel } from "../../styled-component/Labels";
import { ACTIONS } from "../../store/actions";
import { toast } from "react-toastify";
import UploadFilePopup from "../common/uploadFilePopup";
import ViewFilePopup from "../common/viewFilePopup";
import { createUser, getDistributionUrl, getDistributionUsers, updateUser } from "../../Services/userServices";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { createGroup, deleteGroup, getAllGroups, updateGroup } from "../../Services/GroupServices";
import DeleteIcon from '@mui/icons-material/Delete';

function Groups(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  const [groupName, setGroupName] = useState("");
  const [groupAdmin, setGroupAdmin] = useState([]);
  const [groupUser,setGroupUser] = useState([])
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [data, setData] = useState([])
  const [edit,setEdit] = useState(null)
  const [firstNameError, setFirstNameError] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  
  const [roleError, setRoleError] = useState(false);
  const [flag, setFlag] = useState(false);
  const [addEditopen, setOpen] = useState(false);
  const [groupOpen,setGroupOpen] = useState(false);
  
  const [uploadPopup, setUploadPopup] = useState({
    open: false,
    userData: null,
  });

  const [viewPopup, setViewPopup] = useState({
    open: false,
    userData: null,
  });
  
  let RegexString =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let admin_list = useSelector((state) => state.adminListData.adminList);
  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }
  let userData = JSON.parse(localStorage.getItem("UserData"));
  //console.log(admin_list);

  const getGroups = () => {
    let userData = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userData.token}`, distributor_id:params.id ? params.id : userData.id }
  };
  getAllGroups(config)
      .then((res) => {
        if (!res.data.error) {
          setData(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGroups();

    dispatch({
      type: ACTIONS.UPDATE_SIDEBAR_STATE,
      payload: "distributor_list", // can be more optimised using dispatcher
    });

    // if (user_data.role !== 1 && user_data.role !== 2) {
    //   history("/dashboard");
    // }
  }, []);

  const handleAddGroup = (e) => {
    e.preventDefault();
    if (
      groupName !== "" &&
      !groupNameError
    ) {
      let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
    
      let payloadObj = {
        groupName: groupName,
        groupAdmin: [],
        groupUser: [],
        distributorId:params.id ? params.id : userData.id,
        createdByUSer:userData.id
      };
      createGroup(payloadObj,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleGroupClose();
            setGroupNameError(false);
            setGroupName("")
            getGroups();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
    } else {
      if (groupName === "") {
        setGroupNameError(true);
      }
    }
  };

  const editClickOpen = (data) => {
    setFlag(true);
    setGroupOpen(true);
    setEdit(data);
    setGroupName(data.groupName)
    };
  
  

  const handleGroupClose = () => {
    setGroupNameError(false);
    setGroupOpen(false);
  };

  const handleGroupOpen = () => {
    setFlag(false);
    setEdit();
    setGroupNameError(false);
    setGroupName("")
    setGroupOpen(true);
  };

  const handleEditGroup = () =>{
    const payload = {
      distributorId:edit.distributorId,
      groupId:edit._id,
      groupName:groupName
     }
     let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
    updateGroup(payload,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleGroupClose();
            getGroups();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
  }

  const handleGroupStatus = (data) =>{
    const payload = {
      distributorId:data.distributorId,
      groupId:data._id,
      status:!data.status
     }
     let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
    updateGroup(payload,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleGroupClose();
            getGroups();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
  }

  const uploadClickOpen = (row) => {
    setUploadPopup({
      open: true,
      userData: row,
    });
  };
  const viewClickOpen = (row) => {
    setViewPopup({
      open: true,
      userData: row,
    });
  };
  const uploadClickClose = (row) => {
    setUploadPopup({
      open: false,
      userData: null,
    });
  };
  const viewClickClose = (row) => {
    setViewPopup({
      open: false,
      userData: null,
    });
  };

  const handleError = (row) => {
    toast.error(`Upload / View is not available for Super Admin `, {
      toastId: "adminUpload_error",
    });
  };

  const handleRemoveUser = (row) =>{
    const payload = {
      distributorId:row.distributorId,
      groupId:row._id
     }
     let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
    deleteGroup(payload,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            getGroups();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
  }

 


  return (
    <div className="main-app-grid">
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          <div className={classes.listHeader}>List of Distributor Groups</div>
          <div className={classes.addButton}>
            <MMIButton
              style={{
                width: "150px",
                height: "48px",
                fontSize: "16px",
                color: "black",
                marginRight:"10px"
              }}
              color={Colors.Secondary}
              onClick={() => handleGroupOpen()}
            >
              Add Groups
            </MMIButton>
            {/* <MMIButton
              style={{
                width: "150px",
                height: "48px",
                fontSize: "16px",
                color: "black",
              }}
              color={Colors.Secondary}
              onClick={() => addClickOpen(1)}
            >
              Add Users
            </MMIButton> */}
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              textAlign={"center"}
              justifyContent={"space-between"}
              margin={"0px 0px 24px 0px"}
            ></Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{ minHeight: "500px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                    <TableCell>S.No</TableCell>
                      <TableCell>Group Name</TableCell>
                      <TableCell>Excel</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 &&
                      data.map((row,index) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                         <TableCell>{index+1}</TableCell>
                          <TableCell component="th" scope="row">
                          <Link to={`/groups-user/${params.id ? params.id : userData.id}/${row._id}`} state={{groupData:row}}>{row.groupName}</Link>
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                               }}
                            >
                              <Link
                                style={{
                                  color: "#13327c",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  uploadClickOpen(row)
                                }
                              >
                                <UploadIcon />
                                Upload
                              </Link>{" "}
                              <span style={{ margin: "0px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  viewClickOpen(row)
                                }
                              >
                                <VisibilityIcon />
                                View
                              </Link>
                            </div>
                          </TableCell>
                         
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "5px",
                              }}
                            >
                              <Link
                                style={{
                                  color: "#13327c",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  editClickOpen(row)
                                 }
                              >
                                <EditIcon />
                              </Link>
                              <span style={{ margin: "0px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => { if (window.confirm('Are you sure you want to remove group?')) handleRemoveUser(row) } } 
                              >
                               <DeleteIcon/>
                              
                              </Link>
                              {/* <span style={{ margin: "0px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                 handleGroupStatus(row)
                                }
                              >
                               {
                                row.status===true ? <ToggleOnIcon /> :<ToggleOffIcon/>
                               }
                                
                              </Link> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          

          {/** Pop usp of groups */}
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={groupOpen}
            onClose={handleGroupClose}
            classes={{
              paper: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className={classes.dialogTitle}>
              {flag ? "Edit Group" : "Add Group"}
              {/*{strings.EditStaff*/}
              <CloseIcon onClick={handleGroupClose} style={{ cursor: "pointer" }} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  textAlign: "center",
                  padding: "10px 0px",
                  textWeight: "700",
                  color: "black",
                }}
              >
                {flag
                  ? "Edit the details of a group"
                  : "Create a group"}
              </DialogContentText>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Group Name"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                />
                <Textfield
                  className="textfield"
                  placeholder={""}
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                    setGroupNameError(false);
                  }}
                />

                {groupNameError && (
                  <Error
                    className="inputerror"
                    text={"Group name is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
              
              
              

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <MMIButton onClick={handleGroupClose}> Cancel</MMIButton>
              {
                flag ?
                <MMIButton onClick={handleEditGroup}>Edit Group</MMIButton>
                :
                <MMIButton onClick={handleAddGroup}>Add Group</MMIButton>
              }
             
            </DialogActions>
          </Dialog>
        </div>
        {uploadPopup.open && (
          <UploadFilePopup data={uploadPopup} onClose={uploadClickClose} />
        )}

        {viewPopup.open && (
          <ViewFilePopup data={viewPopup} onClose={viewClickClose} />
        )}
      </div>
    </div>
  );
}

export default Groups;


const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },
  gridContainer: {
    padding: "0px 25px",
    "@media (max-width: 991px)": {
      padding: "0px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "700",
  },
  tableHead: {
    background: "#f1f1f1",
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 25px",
  },
  dialog: {
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
  },
  dialogActions: {
    //padding: "20px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
});

let roles = [
  {
    value: "2",
    label: "Admin",
  },
];
