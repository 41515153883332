import { ACTIONS } from "../../actions";

/**
 * Reducer specific to handeling all user data
 */

const initialState = {
  app_url: "",
  name: "",
};

export function apkData(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case ACTIONS.UPDATE_APK_DATA:
      return {
        ...newState,
        ...action.payload,
      };

    default:
      return state;
  }
}
