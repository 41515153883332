import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import ScaleLoader from "react-spinners/ScaleLoader";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import * as Colors from "../../styled-component/colors";
import { ACTIONS } from "../../store/actions";
import { MMIButton } from "../common/Button/button";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { Textfield } from "../../styled-component/Inputs";
import { InputLabel } from "../../styled-component/Labels";
import { Heading3Bold, Error } from "../../styled-component/Labels";
import { getApkData, uploadNewApk } from "../../Services/uploadServices";


const override = {
  display: "flex",
  alignItems: "center",
  marginLeft: "30px",
  marginTop: "15px",
};

const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },
  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "10px 35px",
    textAlign: "start",
    fontSize: "20px",
    fontWeight: "700",
  },
  onDropZone: {
    height: "50px",
    width: "60%",
    display: "flex",
    border: "1px dotted black",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "30px",
    "@media (max-width: 768px)": {
      width: "95%",
    },
  },
  uploadFileBox: {
    width: "55%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid lightgray",
    padding: "0 6px 0 14px",
    marginTop: "15px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.04)",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  fileName: {
    padding: "8px 6px 10px 0",
    fontSize: "13px",
    width: "60%",
    display: "inline-block",
    position: "relative",
    //top: 4px;
  },
  inputItem: {
    display: "flex",
    paddingLeft: "50px",
    alignItems: "baseline",
  },
  dialog: {
    //backgroundColor: theme.palette.background.paper,
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 50px !important",
    paddingTop: "20px !important",
    minHeight: "300px",
  },
  dialogActions: {
    //padding: "20px !important",
  },
});

export default function UploadApk(props) {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const version_regex = "^(d+.)?(d+.)?(*|d+)$";
  const [previewModal, setPreviewModal] = useState({
    fileData: "",
    name: null,
  });

  const [loading, setLoading] = useState(false);

  const [versionName, setVersionName] = useState(null);
  const [versionCode, setVersionCode] = useState(null);

  const [versionNameError, setVersionNameError] = useState(null);
  const [versionCodeError, setVersionCodeError] = useState({
    open: false,
    type: null,
  });

  const [openVpopup, setOpenVpopup] = useState(false);

  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }
  const configHeader = {
    headers: { Authorization: `Bearer ${user_data.token}` }
  };
  

  let apk_data = useSelector((state) => state.apkData);

  const getAllApk = () => {
    getApkData(configHeader).then((res) => {
      if (!res.data.error) {
        dispatch({
          type: ACTIONS.UPDATE_APK_DATA,
          payload: { ...res.data.data },
        });
      }
    });
  };

  useEffect(() => {

    if (user_data.role !== 1) {
      history("/dashboard");
    }

    getAllApk();
    dispatch({
      type: ACTIONS.UPDATE_SIDEBAR_STATE,
      payload: "apkUpload", // can be more optimised using dispatcher
    });
   
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    setPreviewModal({
      fileData: acceptedFiles[0],
      name: acceptedFiles[0].name,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClose = () => {
    setPreviewModal({
      fileData: "",
      name: null,
    });
  };

  const onUpload = () => {
    if (versionName && versionCode) {
      let formData = new FormData();
      formData.append("uploadApk", previewModal.fileData);
      formData.append("id", apk_data.id);
      formData.append("version_name", versionName);
      formData.append("version_code", parseInt(versionCode));
      setLoading(true);

      uploadNewApk(formData,configHeader)
        .then((res) => {
          if (!res.data.error) {
            setPreviewModal({
              fileData: "",
              name: null,
            });
            setLoading(false);
            getAllApk();
            setVersionName(null);
            setVersionCode(null);
            toast.success("upload sucessfully", { toastId: "upload_sucess" });
          } else {
            setLoading(false);
            toast.error(res.data.message, { toastId: "upload_error" });
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          toast.error(e, { toastId: "upload_error" });
        });
    } else {
      setOpenVpopup(true);
    }
  };

  const handleAddDetails = () => {
    console.log(apk_data.version_code)
    if (versionName && versionCode) {
      if (apk_data.version_code == undefined) {
        setOpenVpopup(false);
      } else if(parseInt(versionCode) > parseInt(apk_data.version_code)){
        setOpenVpopup(false);
      }else {
        setVersionCodeError({
          open: true,
          type: "minimum",
        });
      }
    } else {
      if (!versionName) {
        setVersionNameError(true);
      }
      if (!versionCode) {
        setVersionCodeError({
          open: true,
          type: "required",
        });
      }
    }
  };

  const handlePopupClose = () => {
    setOpenVpopup(false);
  };

  return (
    <div className="main-app-grid">
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={6}
              className={classes.gridItemContainer}
            >
              <div className={classes.listHeader}>Upload the Apk file here</div>

              <div
                style={{
                  display: "flex",
                  paddingLeft: "50px",
                  flexDirection: "row",
                }}
              >
                <div className={classes.onDropZone}>
                  <div {...getRootProps()}>
                    <input type="file" {...getInputProps()} />
                    {isDragActive ? (
                      <div>
                        <div>{props.children}</div>
                        <p>Drop image here.</p>
                      </div>
                    ) : (
                      <div>
                        <div>{props.children}</div>
                        <span style={{ display: "flex" }}>
                          <FileUploadIcon />
                          <p>Drop file here, or click to select an file.</p>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {previewModal.name && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "20px",
                  }}
                >
                  <div className={classes.uploadFileBox}>
                    <span className={classes.fileName}>
                      {previewModal.name}
                    </span>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
              {previewModal.name && versionCode && versionName && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    paddingLeft: "50px",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "550",
                        color: `${Colors.darkGray}`,
                      }}
                    >
                      Version Name :{" "}
                    </span>{" "}
                    <span style={{ fontSize: "16px", fontWeight: "550" }}>
                      {" "}
                      {versionName}{" "}
                    </span>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "550",
                        color: `${Colors.darkGray}`,
                      }}
                    >
                      Version Code :{" "}
                    </span>{" "}
                    <span style={{ fontSize: "16px", fontWeight: "550" }}>
                      {" "}
                      {versionCode}
                    </span>
                    <Tooltip title="Edit app details">
                      <IconButton
                        style={{
                          marginLeft: "2rem",
                          cursor: "pointer",
                          color: "#000",
                        }}
                        onClick={() => setOpenVpopup(true)}
                      >
                        <BorderColorIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
              <div style={{ display: "flex", marginTop: "20px" }}>
                {previewModal.name && (
                  <div
                    style={{
                      paddingLeft: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <MMIButton
                      style={{
                        width: "150px",
                        height: "48px",
                        fontSize: "15px",
                        //margin: "12px 0px 32px",
                      }}
                      color={Colors.Secondary}
                      onClick={onUpload}
                    >
                      Upload
                    </MMIButton>
                  </div>
                )}

                {loading && (
                  <ScaleLoader
                    color={"#000"}
                    loading={loading}
                    cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={6}
              className={classes.gridItemContainer}
            >
              <div className={classes.listHeader}>Current Apk</div>
              {apk_data.name && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "30px",
                  }}
                >
                  <div
                    className={classes.uploadFileBox}
                    style={{ justifyContent: "flex-start" }}
                  >
                    <DescriptionIcon />
                    <span
                      className={classes.fileName}
                      style={{ marginLeft: "1rem" }}
                    >
                      {apk_data.name}
                    </span>
                  </div>
                </div>
              )}
              {apk_data.version_name && apk_data.version_code && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    paddingLeft: "50px",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "550",
                        color: `${Colors.darkGray}`,
                      }}
                    >
                      Version Name :{" "}
                    </span>{" "}
                    <span style={{ fontSize: "16px", fontWeight: "550" }}>
                      {" "}
                      {apk_data.version_name}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "550",
                        color: `${Colors.darkGray}`,
                      }}
                    >
                      Version Code :{" "}
                    </span>{" "}
                    <span style={{ fontSize: "16px", fontWeight: "550" }}>
                      {" "}
                      {apk_data.version_code}{" "}
                    </span>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        {openVpopup && (
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={openVpopup}
            classes={{
              paper: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className={classes.dialogTitle}>
              Add apk details
              <CloseIcon
                onClick={handlePopupClose}
                style={{ cursor: "pointer" }}
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  textAlign: "center",
                  padding: "10px 0px",
                  textWeight: "700",
                  color: "black",
                }}
              >
                Enter App details
              </DialogContentText>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Version Name"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                />
                <Textfield
                  className="textfield"
                  placeholder={"Enter Version Name"}
                  value={versionName}
                  onChange={(e) => {
                    setVersionName(e.target.value);
                    setVersionNameError(false);
                  }}
                />

                {versionNameError && (
                  <Error
                    className="inputerror"
                    text={"version name is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Version Code"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <Textfield
                  className="textfield"
                  placeholder={"Enter Version code"}
                  value={versionCode}
                  type="number"
                  onChange={(e) => {
                    setVersionCode(e.target.value);
                    setVersionCodeError({
                      open: false,
                      type: null,
                    });
                  }}
                />
                {versionCodeError.open && (
                  <Error
                    className="inputerror"
                    text={
                      versionCodeError.type === "required"
                        ? "version code is required"
                        : "new version code should be greater then previous version code"
                    }
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <MMIButton onClick={handlePopupClose}> Cancel</MMIButton>
              <MMIButton onClick={handleAddDetails}> Add Details</MMIButton>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
}
