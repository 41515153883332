import CryptoJS from "crypto-js";

const encryptToString = (message) => {
  let secretKey = "aesEncryptionKey";
  let iv = "encryptionIntVec";
  //console.log(message.replaceAll(" ", ""));
  //debugger;
  let cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(
      message.replaceAll(" ", "").replace(/\n/g, "")
    ),
  });

  let keyB = CryptoJS.enc.Utf8.parse(secretKey);

  var decrypted = CryptoJS.AES.decrypt(cipherParams, keyB, {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  let byte = decrypted.toString();

  if (byte !== "") {
    byte = atob(
      decrypted.toString(CryptoJS.enc.Base64).toString(CryptoJS.enc.Utf8)
    );
  }

  return byte;
};

export { encryptToString };
