import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import * as Colors from "../../styled-component/colors";
import { MMIButton } from "./Button/button";
import { getExcelData } from "../../Services/uploadServices";
import {
  editFileData,
  addFileData,
  deleteFileData,
} from "../../Services/fileServices";
import EditPopup from "./editPopup";
import { decimalToHex } from "../../utils/Constant";
import Loader from "./Loader";

const useStyles = makeStyles({
  dialog: {
    //backgroundColor: theme.palette.background.paper,
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
    overflowX: "hidden !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "10px !important",
    paddingBottom: "5px !important",
    minHeight: "500px",
    overflowX: "hidden !important",
    "@media (max-width: 1199px)": {
      paddingBottom: "0px !important",
    },
  },
  dialogSmallContent: {
    padding: "0px 25px !important",
    paddingTop: "10px !important",
    paddingBottom: "5px !important",
    minHeight: "60px",
    overflowX: "hidden !important",
    "@media (max-width: 1199px)": {
      paddingBottom: "0px !important",
    },
  },
  dialogActions: {
    //padding: "10px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
  gridContainer: {
    margin: "0px !important",
  },
  gridItemContainer: {
    "@media (max-width: 768px)": {
      padding: "0px !important",
    },
  },
  listHeader: {
    padding: "15px 25px",
    fontSize: "20px",
    fontWeight: "700",
    "@media (max-width: 1199px)": {
      //marginTop: "50px",
    },
  },

  listHead: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  tableHead: {
    background: "#f1f1f1",
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  gridItem: {
    paddingLeft: "10px !important",
    maxWidth: "98%",
    "@media (max-width: 768px)": {
      paddingLeft: "0px !important",
      paddingRight: "20px !important",
    },
    "@media (max-width: 991px)": {
      paddingLeft: "0px !important",
      paddingRight: "20px !important",
    },
  },
});

export default function ViewFilePopup(props) {
  const classes = useStyles();

  let user_data = props.data.userData;
  const [fileData, setFileData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const getUploadedFiles = () => {
    setShowLoader(true);
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
  };
    getExcelData(config).then((res) => {
      if (!res.data.error) {
        setShowLoader(false);
        setFileData(res.data.data.fileData);
      }else{
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    getUploadedFiles();
  }, []);

  const { open, userData } = props.data;

  const handleEdit = (row) => {
    setEditOpen(true);
    setSelectedItem(row);
  };

  const onClose = () => {
    setEditOpen(false);
    setSelectedItem(null);
    setAddOpen(false);
  };

  const handleAdd = () => {
    setAddOpen(true);
  };

  const handleDeleteOpen = (row) => {
    setDeleteOpen(true);
    setSelectedItem(row);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedItem(null);
  };

  const onSaveClick = (obj, type) => {
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
    };
    if (obj) {
      let payloadObj = {
        groupId: userData._id,
        ...obj,
      };
      if (type === "edit") {
        editFileData(payloadObj,config)
          .then((res) => {
            console.log(res);
            if (!res.data.error) {
              getUploadedFiles();
              onClose();
              toast.success(res.data.message, { toastId: "update_error" });
            } else {
              toast.error(res.data.message, { toastId: "update_error" });
            }
          })
          .catch((err) => {
            toast.error(err.message, { toastId: "update_error" });
          });
      }else if (type === "add") {
        addFileData(payloadObj,config)
          .then((res) => {
            console.log(res);
            if (!res.data.error) {
              getUploadedFiles();
              onClose();
              toast.success(res.data.message, { toastId: "update_error" });
            } else {
              toast.error(res.data.message, { toastId: "update_error" });
            }
          })
          .catch((err) => {
            toast.error(err.message, { toastId: "update_error" });
          });
      }

      //console.log(payloadObj);
    }
  };

  const onDelete = () => {
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: userData._id }
    };
    let payloadObj = {
      groupId: userData._id,
      hexaUID: selectedItem.hexaUID,
    };
    deleteFileData(payloadObj,config)
      .then((res) => {
        if (!res.data.error) {
          getUploadedFiles();
          handleDeleteClose();
          toast.success(res.data.message, { toastId: "update_error" });
        } else {
          toast.error(res.data.message, { toastId: "update_error" });
        }
      })
      .catch((err) => {
        toast.error(err.message, { toastId: "update_error" });
      });
  };
console.log("user_data",user_data)
  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <Loader showLoader={showLoader} />
      <DialogTitle className={classes.dialogTitle}>
        View file data
        {/*{strings.EditStaff*/}
        <CloseIcon onClick={props.onClose} style={{ cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            textAlign: "center",
            padding: "10px 0px",
            textWeight: "700",
            color: "black",
          }}
        >

          {user_data && user_data.groupName ? user_data.groupName : "Group" }'s upload file data
        </DialogContentText>
        <Grid container spacing={2} className={classes.gridContainer}>
          <div className={classes.listHead}>
            <div className={classes.listHeader}>Uploaded Excel data</div>
            {fileData.length > 0 && (
              <MMIButton
                style={{
                  width: "150px",
                  height: "48px",
                  fontSize: "16px",
                  color: "black",
                }}
                color={Colors.Secondary}
                onClick={() => handleAdd()}
              >
                Add new row
              </MMIButton>
            )}
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              {fileData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "400px" }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>UID</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Organization</TableCell>
                        <TableCell>Aux1</TableCell>
                        <TableCell>Aux2</TableCell>
                        <TableCell>Aux3</TableCell>
                        <TableCell>Notes</TableCell>

                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileData.length > 0 &&
                        fileData.map((row) => (
                          <TableRow
                            key={row.UID}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.hexaUID ? row.hexaUID : decimalToHex(row.UID)}
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  padding: "5px 0px",
                                }}
                              >
                                {row.type}
                              </div>
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.organization}</TableCell>
                            <TableCell>{row.aux1}</TableCell>
                            <TableCell>{row.aux2}</TableCell>
                            <TableCell>{row.aux3}</TableCell>
                            <TableCell>{row.notes}</TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "5px",
                                }}
                              >
                                <Link
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip title="Edit data">
                                    <IconButton
                                      style={{ color: "#000" }}
                                      onClick={() => handleEdit(row)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Link>{" "}
                                <span
                                  style={{
                                    margin: "0px 8px",
                                    lineHeight: "2rem",
                                  }}
                                >
                                  |
                                </span>{" "}
                                <Link
                                  style={{
                                    color: `${Colors.black}`,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip title="Delete data">
                                    <IconButton
                                      style={{ color: "#000" }}
                                      onClick={() => handleDeleteOpen(row)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  Sorry! No data found{" "}
                </div>
              )}
            </Grid>
          </Grid>
          {editOpen && (
            <EditPopup
              open={editOpen}
              flag="edit"
              onClose={onClose}
              data={selectedItem}
              onSave={onSaveClick}
            />
          )}

          {addOpen && (
            <EditPopup
              open={addOpen}
              flag="add"
              onClose={onClose}
              onSave={onSaveClick}
            />
          )}
          {deleteOpen && (
            <Dialog
              open={deleteOpen}
              maxWidth="xs"
              onClose={handleDeleteClose}
              classes={{
                paper: classes.dialog,
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                className={classes.dialogTitle}
              >
                Delete confirmation
                <CloseIcon
                  onClick={handleDeleteClose}
                  style={{ cursor: "pointer" }}
                />
              </DialogTitle>
              <DialogContent className={classes.dialogSmallContent}>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the row ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                  onClick={handleDeleteClose}
                >
                  No
                </div>
                <MMIButton
                  style={{
                    width: "70px",
                    height: "28px",
                    fontSize: "15px",
                    color: "black",
                    marginLeft: "10px",
                  }}
                  color={Colors.Secondary}
                  onClick={onDelete}
                >
                  Yes
                </MMIButton>
              </DialogActions>
            </Dialog>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <MMIButton onClick={props.onClose}> Cancel</MMIButton>
      </DialogActions>
    </Dialog>
  );
}
