export const white = "#FFFFFF";
export const black = "#272727";
export const Primary = "#002E6D";
export const Secondary = "#FFCC41";
export const blue = "#13327C";
export const nightGray = "#393939";
export const gray2c = "#2C2C2C";
export const gray97 = "#979797";
export const grayf9 = "#f9f9f9";
export const gray74 = "#747474";
export const gray80 = "#808080";
export const neutrallightGray = "#F1F1F1";
export const midGray = "#545454";
export const graye5 = "#5E5E5E";
export const darkGray = "#4b4b4b";
export const lightGray = "#969696";
export const mainlightGray = "#F3F3F3";
export const whiteGray = "#EAEAEA";
export const lightGreen = "#EBF7F9";
export const midGreen = "#4ECDC4";
export const greenc0 = "#C0E4EC";
export const nightBlue = "#003862";
export const highlightBlue = "#3AB1CA";
export const highlightOrange = "#FC7753";
export const lightOverlay = "rgba(255,255,255,0.80)";
export const darkOverlay = "rgba(0,0,0,0.35)";
export const success = "#74B711";
export const warning = "#FFC628";
export const error = "#D72F2F";
export const yallow = "#FF9900";
