import axios from "axios";
import { dev_base_url } from "../utils/configUrl";


const createGroup = (payload,headers) => {
    return axios
      .post(`${dev_base_url}/group/create_group`, payload,headers)
      .then((response) => response)
      .catch((e) => e.response);
};

const getAllGroups = (headers) => {
    return axios
      .get(`${dev_base_url}/group/get_groups`,headers)
      .then((response) => response)
      .catch((e) => e.response);
};  

const updateGroup = (payload,headers) => {
    return axios
      .post(`${dev_base_url}/group/update_group`, payload,headers)
      .then((response) => response)
      .catch((e) => e.response);
};

const deleteGroup = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/group/delete_group`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const createGroupUser = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/group/create_group_user`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const removeGroupUser = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/group/remove_user`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const UpdateGroupUser = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/group/update_group_user`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const resendPasswordGroupUSer = (payload,headers) => {
  return axios
    .post(`${dev_base_url}/group/resend_password`, payload,headers)
    .then((response) => response)
    .catch((e) => e.response);
};

const getAllGroupUsers = (headers) => {
  return axios
    .get(`${dev_base_url}/group/get_group_users`,headers)
    .then((response) => response)
    .catch((e) => e.response);
}; 

  export {createGroup,getAllGroups,updateGroup,deleteGroup,createGroupUser, getAllGroupUsers,resendPasswordGroupUSer,removeGroupUser,UpdateGroupUser}