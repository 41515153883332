import React from "react";
import { Routes, Route } from "react-router";
import Signin from "../component/Auth/signin";
import DistributionList from "../component/Dashboard/distributionList";
import Dashboard from "../component/Dashboard/dashboard";
import UserList from "../component/Dashboard/userList";
import ViewFileData from "../component/FileData/viewFileData";
import UploadApk from "../component/Dashboard/uploadApk";
import DecryptedData from "../component/FileData/decryptedData";
import EncryptedFiles from "../component/FileData/encryptedFiles";
import LoggedOutRoute from "./loggedOutRoutes";
import LoggedInRoute from "./loggedInRoutes";
import Groups from "../component/Distributor/groups";
import GroupUsers from "../component/Distributor/groupUsers";
import ChangePassword from "../component/Dashboard/changePassword";
import ForgotPassword from "../component/Auth/forgotPassword";


const AppRoutes = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <LoggedOutRoute>
            <Signin />
          </LoggedOutRoute>
        }
      />
      <Route
        exact
        path="/forgotPassword"
        element={
          <LoggedOutRoute>
            <ForgotPassword />
          </LoggedOutRoute>
        }
      />

<Route
        exact
        path="/distribution_list"
        element={
          <LoggedInRoute>
            <DistributionList />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/apkUpload"
        element={
          <LoggedInRoute>
            <UploadApk />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/decryptedFile"
        element={
          <LoggedInRoute>
            <DecryptedData />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/encryptedfiles/:id?"
        element={
          <LoggedInRoute>
            <EncryptedFiles />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/groups/:id?"
        element={
          <LoggedInRoute>
            <Groups />
          </LoggedInRoute>
        }
      />



    <Route
        exact
        path="/groups-user/:distid?/:id?"
        element={
          <LoggedInRoute>
            <GroupUsers />
          </LoggedInRoute>
        }
      />



<Route
        exact
        path="/change_password"
        element={
          <LoggedInRoute>
            <ChangePassword />
          </LoggedInRoute>
        }
      />
      
    </Routes>
  );
};
export default React.memo(AppRoutes);
