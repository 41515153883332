import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from '@mui/icons-material/Edit';
import * as Colors from "../../styled-component/colors";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { MMIButton } from "../common/Button/button";
import { Heading3Bold, Error } from "../../styled-component/Labels";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Textfield } from "../../styled-component/Inputs";
import { InputLabel } from "../../styled-component/Labels";
import { ACTIONS } from "../../store/actions";
import { toast } from "react-toastify";
import UploadFilePopup from "../common/uploadFilePopup";
import ViewFilePopup from "../common/viewFilePopup";
import { createUser, getDistributionUrl, getDistributionUsers, updateUser } from "../../Services/userServices";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { createGroupUser, getAllGroupUsers, removeGroupUser, resendPasswordGroupUSer, UpdateGroupUser } from "../../Services/GroupServices";
import ExporExcel from "../FileData/ExcelExport";
import { getExcelData } from "../../Services/uploadServices";
import DeleteIcon from '@mui/icons-material/Delete';

function GroupUsers(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  const location = useLocation()
  const { groupData } = location.state ? location.state : {};
  const userGroupData =JSON.parse(localStorage.getItem("UserData"));;
  const [groupName, setGroupName] = useState("");
  const [groupAdmin, setGroupAdmin] = useState([]);
  const [groupUser,setGroupUser] = useState([])
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(4);
  const [data, setData] = useState([])
  const [edit,setEdit] = useState(null)
  const [firstNameError, setFirstNameError] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [uploadPopup, setUploadPopup] = useState({
    open: false,
    userData: userGroupData.role === 3 ? userGroupData.groupData :groupData ? groupData : {_id:params.id,groupName:""},
  });

  const [viewPopup, setViewPopup] = useState({
    open: false,
    userData: userGroupData.role === 3 ? userGroupData.groupData :groupData ? groupData : {_id:params.id,groupName:""},
  });
  const [roleError, setRoleError] = useState(false);
  const [flag, setFlag] = useState(false);
  const [addEditopen, setOpen] = useState(false);
  const [groupOpen,setGroupOpen] = useState(false);
const [fileData,setFileData] = useState([])
  let RegexString =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let admin_list = useSelector((state) => state.adminListData.adminList);
  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }
  const uploadClickOpen = () => {
    setUploadPopup((prevState) => ({
      ...prevState,
      open: true
    }));
  };
  const viewClickOpen = () => {
    setViewPopup((prevState) => ({
      ...prevState,
      open: true
    }));
  };
  const uploadClickClose = (row) => {
    setUploadPopup((prevState) => ({
      ...prevState,
      open: false
    }));
  };
  const viewClickClose = () => {
    setViewPopup((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  //console.log(admin_list);
  const getAllAdminList = () => {
    let userData = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { 
        Authorization: `Bearer ${userData.token}`, 
        group_id:params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id,
        distributor_id:params.distid ? params.distid : userGroupData.role === 3 ? userGroupData.groupData.distributorId :userData.id, 
      }
  };
  getAllGroupUsers(config)
      .then((res) => {
        if (!res.data.error) {
          setData(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllAdminList();
    // getUploadedFiles()
    dispatch({
      type: ACTIONS.UPDATE_SIDEBAR_STATE,
      payload: "distributor_list", // can be more optimised using dispatcher
    });

    // if (user_data.role !== 1 && user_data.role !== 2) {
    //   history("/dashboard");
    // }
  }, []);

  const handleChangeRole = (event) =>{
    setRole(event.target.value)
  }

  const handleRemoveUser = (id) =>{
    let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
      let payloadObj = {
        groupId:params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id,
        distributorId:params.distid ? params.distid : userGroupData.role === 3 ? userGroupData.groupData.distributorId :userData.id,
        group_userId:id
      };
      removeGroupUser(payloadObj,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleClose();
            getAllAdminList();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
  }
  
  const handleAddUser = (e) => {
    e.preventDefault();
    if (
      email !== "" &&
      role !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      !emailError
    ) {
      let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
      let payloadObj = {
        firstname: firstName,
        lastname: lastName,
        role: role,
        email: email,
        groupId:params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id,
        distributorId:params.distid ? params.distid : userGroupData.role === 3 ? userGroupData.groupData.distributorId :userData.id,
        createdByUSer:userData.id
      };
      createGroupUser(payloadObj,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleClose();
            getAllAdminList();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
    } else {
      if (firstName === "") {
        setFirstNameError(true);
      }
      if (lastName === "") {
        setLastNameError(true);
      }
      if (password === "") {
        setPasswordError(true);
      }
      if (email === "") {
        setEmailError(true);
      }
      if (role === "") {
        setRoleError(true);
      }
    }
  };

  
  const handleClose = () => {
    setFirstNameError(false);
    setEmailError(false);
    setRoleError(false);
    setOpen(false);
  };

  const handleGroupClose = () => {
    setGroupNameError(false);
    setGroupOpen(false);
  };

  const handleGroupOpen = () => {
    setGroupOpen(true);
  };

 

  const validateEmail = (value) => {
    if (!RegexString.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const addClickOpen = (value) => {
    console.log(value);

    setFlag(false);
    setOpen(true);
    setEdit(null);
    setFirstName("");
    setEmail("");
    setLastName("");
    setPassword("");
    setRole(4);
  };

  const editClickOpen = (data) => {
    setFlag(true);
    setOpen(true);
    setEdit(data);
    setFirstName(data.firstname);
    setLastName(data.lastname);
    setEmail(data.email);
    setRole(data.role)
    setPassword("");
  };

 
  const handleUpdateUser = (e) =>{
    e.preventDefault();
    if (
      role !== "" &&
      firstName !== "" &&
      lastName !== ""
     ) {

    let payloadObj = {
      groupId:params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id,
      distributorId:params.distid ? params.distid : userGroupData.role === 3 ? userGroupData.groupData.distributorId :userData.id,
      group_userId:edit._id,
      role:role,
      firstname: firstName,
      lastname: lastName
    };
  
    let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
    UpdateGroupUser(payloadObj,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleClose();
            getAllAdminList();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
      }else {
        if (firstName === "") {
          setFirstNameError(true);
        }
        if (lastName === "") {
          setLastNameError(true);
        }
        
        if (role === "") {
          setRoleError(true);
        }
      }
    
  }

  const handleUpdateStatus = (data) =>{
    let userData = JSON.parse(localStorage.getItem("UserData"));
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
      let payloadObj = {
        groupId:params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id,
        distributorId:params.distid ? params.distid : userGroupData.role === 3 ? userGroupData.groupData.distributorId :userData.id,
        group_userId:data._id,
        status:!data.status
      };
      UpdateGroupUser(payloadObj,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleClose();
            getAllAdminList();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
  }

  const handleError = (row) => {
    toast.error(`Upload / View is not available for Super Admin `, {
      toastId: "adminUpload_error",
    });
  };

  const getUploadedFiles = () => {
    let userDatas = JSON.parse(localStorage.getItem("UserData"));
    const config = {
      headers: { Authorization: `Bearer ${userDatas.token}`, group_id: params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id }
  };
    getExcelData(config).then((res) => {
      if (!res.data.error) {
        const newArr = res.data.data.fileData.map(({_id, ...rest}) => {
          return rest;
        });
        return newArr;
      }
    });
  };

  const group_id = params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id
  const excelFileName = groupData ? groupData.groupName : userGroupData.groupData ? userGroupData.groupData.groupName : ""
  const handleResendPassword = (data) =>{
    let userData = JSON.parse(localStorage.getItem("UserData"));
    const payload = {
      groupUserId:data._id,
      groupId:params.id ? params.id : userGroupData.role === 3 ? userGroupData.groupData._id :params.id,
      distributorId:params.distid ? params.distid : userGroupData.role === 3 ? userGroupData.groupData.distributorId :userData.id
     }
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` }
    };
    resendPasswordGroupUSer(payload,config)
        .then((res) => {
          if (!res.data.error) {
            toast.success(res.data.message, { toastId: "adminCreate_error" });
            handleClose();
            getAllAdminList();
          } else {
            toast.error(res.data.message, { toastId: "adminCreate_error" });
          }
        })
        .catch((err) => {
          toast.error(err.message, { toastId: "adminCreate_error" });
        });
  }


  return (
    <div className="main-app-grid">
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          <div className={classes.listHeader}>List of Distributor {groupData ? groupData.groupName : userGroupData.groupData ? userGroupData.groupData.groupName : ""} Users</div>
          <div className={classes.addButton}>
          <ExporExcel group_id={group_id} fileName={excelFileName+"-"+new Date().toJSON()}/>
          <Link
                                style={{
                                  color: "#13327c",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "150px",
                                  height: "48px",
                                  fontSize: "16px",
                                  justifyContent:"center",
                                  background:`${Colors.lightGray}`
                                
                                }}
                                onClick={() =>
                                  uploadClickOpen()
                                }
                              >
                                <UploadIcon />
                                Upload
                              </Link>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                  width: "150px",
                                  height: "48px",
                                  fontSize: "16px",
                                  justifyContent:"center",
                                  margin:"0 10px",
                                  background:`${Colors.highlightBlue}`
                                }}
                                onClick={() =>
                                  viewClickOpen()
                                }
                              >
                                <VisibilityIcon />
                                View
                              </Link>
            <MMIButton
              style={{
                width: "150px",
                height: "48px",
                fontSize: "16px",
                color: "black",
              }}
              color={Colors.Secondary}
              onClick={() => addClickOpen(1)}
            >
              Add Users
            </MMIButton>
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              textAlign={"center"}
              justifyContent={"space-between"}
              margin={"0px 0px 24px 0px"}
            ></Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{ minHeight: "500px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                    <TableCell>S.No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 &&
                      data.map((row,index) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                         <TableCell>{index+1}</TableCell>
                          <TableCell component="th" scope="row">
                          <Link to={`/encryptedfiles/${row._id}`} state={{groupData:row}}>{row.firstname} {row.lastname}</Link>
                          </TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.role===3 ? "Admin" : "User"}</TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "5px",
                              }}
                            >
                              <Link
                                style={{
                                  color: "#13327c",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  editClickOpen(row)
                                 }
                              >
                                <EditIcon />
                              </Link>{" "}
                              <span style={{ margin: "0px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => { if (window.confirm(`Are you sure you want to ${row.status ? "deactivate" : "activate"}  user from this group?`)) handleUpdateStatus(row) } } 
                               
                              >
                               {
                                row.status===true ? <ToggleOnIcon /> :<ToggleOffIcon/>
                               }
                                
                              </Link>
                              <span style={{ margin: "0px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => { if (window.confirm('Are you sure you want to remove user from this group?')) handleRemoveUser(row._id) } } 
                               
                              >
                               <DeleteIcon/>
                              
                              </Link>
                              {row.isFirstTime &&
                              <>
                              <span style={{ margin: "0px 8px" }}>|</span>{" "}
                              <Link
                                style={{
                                  color: `${Colors.black}`,
                                  display: "flex",
                                  alignItems: "center"
                                }}
                                onClick={() => { if (window.confirm('Are you sure you want to send password to this user?')) handleResendPassword(row) } } 
                                
                              >
                                Resend Password
                              </Link>
                              </>
                            }
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={addEditopen}
            onClose={handleClose}
            classes={{
              paper: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className={classes.dialogTitle}>
              {flag ? "Edit User" : "Add User"}
              {/*{strings.EditStaff*/}
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  textAlign: "center",
                  padding: "10px 0px",
                  textWeight: "700",
                  color: "black",
                }}
              >
                {flag
                  ? "Edit the details of a user"
                  : "Invite a user"}
              </DialogContentText>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="First Name"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                />
                <Textfield
                  className="textfield"
                  placeholder={""}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameError(false);
                  }}
                />

                {firstNameError && (
                  <Error
                    className="inputerror"
                    text={"First name is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Last Name"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <Textfield
                  className="textfield"
                  placeholder={""}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameError(false);
                  }}
                />
                {lastNameError && (
                  <Error
                    className="inputerror"
                    text={"Last name is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Email"
                  
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <Textfield
                  className="textfield"
                  placeholder={""}
                  disabled={flag? true:false}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  onBlur={(ev) => {
                    validateEmail(ev.target.value);
                  }}
                />
                {emailError && (
                  <Error
                    className="inputerror"
                    text={"Enter a correct email address"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
              {/* <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Password"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />
                <Textfield
                  className="textfield"
                  placeholder={""}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                />
                {passwordError && (
                  <Error
                    className="inputerror"
                    text={"Password is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div> */}

              <div className={classes.dialogGridItem}>
                <InputLabel
                  text="Role"
                  color={Colors.nightGray}
                  padding={"0 0 8px 0"}
                  className={"inputlabel"}
                  style={{ marginTop: "25px" }}
                />

                <TextField
                  className={classes.dialogTextField}
                  select
                  placeholder={"Select a role"}
                  value={role}
                  onChange={handleChangeRole}
                >
                  {roles.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value ? option.value : role}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {roleError && (
                  <Error
                    className="inputerror"
                    text={"Role is required"}
                    color={Colors.error}
                    margin={"4px 0 8px 0"}
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <MMIButton onClick={handleClose}> Cancel</MMIButton>
              {
                flag ?
                <MMIButton onClick={handleUpdateUser}>Edit User</MMIButton>
                :
                <MMIButton onClick={handleAddUser}>Add User</MMIButton>
              }
             
            </DialogActions>
          </Dialog>



        </div>
        {uploadPopup.open && (
          <UploadFilePopup data={uploadPopup} onClose={uploadClickClose} />
        )}

        {viewPopup.open && (
          <ViewFilePopup data={viewPopup} onClose={viewClickClose} />
        )}
      </div>
    </div>
  );
}

export default GroupUsers;


const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },
  gridContainer: {
    padding: "0px 25px",
    "@media (max-width: 991px)": {
      padding: "0px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "700",
  },
  tableHead: {
    background: "#f1f1f1",
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 25px",
  },
  dialog: {
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
  },
  dialogActions: {
    //padding: "20px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
});

let roles = [
  {
    value: 3,
    label: "Group Admin",
  },
  {
    value: 4,
    label: "Group User",
  }
];
