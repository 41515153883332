import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import * as Colors from "../../styled-component/colors";
import Header from "../common/Header/header";
import Sidebar from "../common/Sidebar/sidebar";
import { MMIButton } from "../common/Button/button";
import WarningPopup from "../common/warningPopup";
import { ACTIONS } from "../../store/actions";
import {
  uploadExcel,
  getExcelData,
  AddNewExcel,
  AppendExisting,
} from "../../Services/uploadServices";

const useStyles = makeStyles({
  mainMidContainer: {
    padding: "110px 24px 0 216px",

    "@media (max-width: 991px)": {
      padding: "110px 15px 0",
    },
    "@media (min-width: 1660px)": {
      padding: "110px 24px 0 250px",
    },
  },

  homepageHolder: {
    paddingBottom: "80px",
    background: "#fafafa",
    minHeight: "500px",
  },
  listHeader: {
    padding: "15px 25px",
    fontSize: "20px",
    fontWeight: "700",
    "@media (max-width: 1199px)": {
      marginTop: "50px",
    },
  },
  tableHead: {
    background: "#f1f1f1",
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 25px",
  },
  dialog: {
    //backgroundColor: theme.palette.background.paper,
    border: `1px solid  ${Colors.midGray} !important`,
    borderRadius: "8px !important",
    marginTop: "20px !important",
  },

  dialogTitle: {
    borderBottom: `1px solid  ${Colors.midGray} !important`,
    fontSize: "1rem !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    padding: "0px 25px !important",
    paddingTop: "20px !important",
  },
  dialogActions: {
    //padding: "20px !important",
  },
  dialogGridItem: {
    width: "80%",
    margin: "auto",
    "@media (max-width: 991px)": {
      width: "100%",
    },
  },
  dialogTextField: {
    width: "100%",
  },
  uploadFileBox: {
    width: "55%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid lightgray",
    padding: "0 6px 0 14px",
    marginTop: "15px",
    position: "relative",
    background: "rgba(0, 0, 0, 0.04)",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  fileName: {
    padding: "8px 6px 10px 0",
    fontSize: "13px",
    width: "60%",
    display: "inline-block",
    position: "relative",
    //top: 4px;
  },
  gridContainer: {
    margin: "0px !important",
  },
  gridItemContainer: {
    "@media (max-width: 768px)": {
      padding: "0px !important",
    },
  },
  onDropZone: {
    height: "50px",
    width: "60%",
    display: "flex",
    border: "1px dotted black",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "30px",
    "@media (max-width: 768px)": {
      width: "95%",
    },
  },
});

function Dashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  //user data from redux store
  let user_data = useSelector((state) => state.userData.data);
  if (user_data.email === "") {
    user_data = JSON.parse(localStorage.getItem("UserData"));
  }

  let file_data = useSelector((state) => state.fileData);

  const [previewModal, setPreviewModal] = useState({
    fileData: "",
    name: null,
  });

  const [conflictPopup, setConflictPopup] = useState({
    open: false,
    data: [],
  });

  const getUploadedFiles = () => {
    let payloadObj = {
      admin_id: user_data.id,
    };
    getExcelData(payloadObj).then((res) => {
      if (!res.data.error) {
        dispatch({
          type: ACTIONS.GET_ALL_FILE_DATA,
          payload: res.data.data,
        });
      }
    });
  };

  useEffect(() => {
    getUploadedFiles();
    dispatch({
      type: ACTIONS.UPDATE_SIDEBAR_STATE, // can be more optimised
      payload: "dashboard",
    });
    if (user_data.admin_type === 1) {
      history("/user");
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    setPreviewModal({
      fileData: acceptedFiles[0],
      name: acceptedFiles[0].name,
    });
  }, []);
  const onUpload = () => {
    let user = JSON.parse(localStorage.getItem("UserData"));
    let formData = new FormData();
    formData.append("uploadFile", previewModal.fileData);
    formData.append("admin_id", user.id);
    uploadExcel(formData)
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          setPreviewModal({
            fileData: "",
            name: null,
          });
          getUploadedFiles();
          toast.success("upload sucessfully", { toastId: "upload_sucess" });
        } else {
          toast.error(res.data.message, { toastId: "upload_error" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onAddNew = () => {
    let user = JSON.parse(localStorage.getItem("UserData"));
    let formData = new FormData();
    formData.append("uploadFile", previewModal.fileData);
    formData.append("admin_id", user.id);
    AddNewExcel(formData)
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          setPreviewModal({
            fileData: "",
            name: null,
          });
          getUploadedFiles();
          toast.success("updated sucessfully", { toastId: "update_sucess" });
        } else {
          toast.error(res.data.message, { toastId: "update_error" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClose = () => {
    setPreviewModal({
      fileData: "",
      name: null,
    });
  };

  const handleConflictClose = () => {
    setConflictPopup({
      open: false,
      data: [],
    });
  };

  const onAppend = () => {
    let user = JSON.parse(localStorage.getItem("UserData"));
    let formData = new FormData();
    formData.append("uploadFile", previewModal.fileData);
    formData.append("admin_id", user.id);
    formData.append("uploaded_data", JSON.stringify(file_data.fileData));

    AppendExisting(formData)
      .then((res) => {
        if (!res.data.error) {
          setPreviewModal({
            fileData: "",
            name: null,
          });
          getUploadedFiles();
          if (res.data.conflictData.length > 0) {
            setConflictPopup({
              open: true,
              data: [...res.data.conflictData],
            });
          } else {
            toast.success(res.data.message, { toastId: "update_sucess" });
          }
        } else {
          toast.error(res.data.message, { toastId: "update_error" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="main-app-grid">
      <Sidebar />
      <Header />
      <div className={classes.mainMidContainer}>
        <div className={classes.homepageHolder}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={6}
              className={classes.gridItemContainer}
            >
              <div className={classes.listHeader}>Upload the Excel file</div>

              <div
                style={{
                  display: "flex",
                  paddingLeft: "50px",
                  flexDirection: "row",
                }}
              >
                <div className={classes.onDropZone}>
                  <div {...getRootProps()}>
                    <input type="file" {...getInputProps()} />
                    {isDragActive ? (
                      <div>
                        <div>{props.children}</div>
                        <p>Drop image here.</p>
                      </div>
                    ) : (
                      <div>
                        <div>{props.children}</div>
                        <p>Drop file here, or click to select an file.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {previewModal.name && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "20px",
                  }}
                >
                  <div className={classes.uploadFileBox}>
                    <span className={classes.fileName}>
                      {previewModal.name}
                    </span>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
              <div style={{ display: "flex", marginTop: "20px" }}>
                {previewModal.name && (
                  <div
                    style={{
                      paddingLeft: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <MMIButton
                      style={{
                        width: "150px",
                        height: "48px",
                        fontSize: "15px",
                        //margin: "12px 0px 32px",
                      }}
                      color={Colors.Secondary}
                      onClick={file_data.fileName ? onAddNew : onUpload}
                    >
                      {file_data.fileName ? "Add as New" : "Upload"}
                    </MMIButton>
                  </div>
                )}
                {previewModal.name && file_data.fileName && (
                  <div
                    style={{
                      paddingLeft: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <MMIButton
                      style={{
                        width: "150px",
                        height: "48px",
                        fontSize: "15px",
                        //margin: "12px 0px 32px",
                      }}
                      color={Colors.Secondary}
                      onClick={onAppend}
                    >
                      Append Existing
                    </MMIButton>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={6}
              className={classes.gridItemContainer}
            >
              <div className={classes.listHeader}>Uploaded file</div>
              {file_data.fileName && (
                <div
                  style={{
                    paddingLeft: "50px",
                    marginTop: "30px",
                  }}
                >
                  <div className={classes.uploadFileBox}>
                    <span className={classes.fileName}>
                      {file_data.fileName}
                    </span>
                    <DeleteIcon
                      //onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          {conflictPopup && (
            <WarningPopup
              popupOpen={conflictPopup.open}
              handleClose={handleConflictClose}
              conflict_data={conflictPopup.data}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
