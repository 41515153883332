export const ACTIONS = {
  GET_USER_DATA: "GET_USER_DATA",
  SIDE_BAR_STATE: "SIDE_BAR_STATE",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  GET_ALL_FILE_DATA: "GET_ALL_FILE_DATA",
  ADD_FILE_DATA: "ADD_FILE_DATA",
  REPLACE_FILE_DATA: "REPLACE_FILE_DATA",
  GET_ALL_ADMIN_LIST: "GET_ALL_ADMIN_LIST",
  ADD_ADMIN_LIST: "ADD_ADMIN_LIST",
  UPDATE_APK_DATA: "UPDATE_APK_DATA",
  UPDATE_SIDEBAR_STATE: "UPDATE_SIDEBAR_STATE",
};
